<template>
  <div class="content-tab-facture-libre" ref="content">
    <div class="body-box-rapport" style="width: 100%">
      <div class="table-rapport-style">
        <div class="box-all-filter">
          <div class="box-label-champ mr-2">
            <div class="label-box-style-title">
              <span class="title-tabel">Facture Libre</span>
            </div>
          </div>
          <div class="box-label-champ mr-2" v-if="checkPermission('FLFDD')">
            <div class="label-box-style w-63-px">
              <span class="title-tabel">Date début</span>
            </div>
            <date-picker
              value-type="format"
              type="date"
              v-model="date_debut"
              :clear-button="true"
              @change="handleFiltre"
              :disabled-date="disabledStartDateFilter"
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter"
            ></date-picker>
          </div>
          <div class="box-label-champ mr-2" v-if="checkPermission('FLFDF')">
            <div class="label-box-style w-63-px">
              <span class="title-tabel">Date fin</span>
            </div>
            <date-picker
              value-type="format"
              type="date"
              v-model="date_fin"
              @change="handleFiltre"
              :clear-button="true"
              :disabled-date="disabledEndDateFiltre"
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter"
            ></date-picker>
          </div>
          <div class="box-label-champ mr-2">
            <div class="label-box-style w-93-px">
              <span class="title-tabel">Vue à afficher</span>
            </div>
            <b-form-select
              class="b-form-select-new-style w-130-px"
              v-model="vueToDisplay"
              :options="vueOptions"
              text-field="text"
              value-field="value"
              @change="onChangeVue"
            ></b-form-select>
          </div>
          <input-file-excel-num-dossier
            v-show="true"
            :value="nums"
            @searchWithNumDossier="searchWithMultipleFacture"
            :fetchFuntion="fecthFactureNumbersInExcelFile"
            :showCount="true"
          />
          <div class="row header-component-facture-libre">
            <div class="error-message ml-3">
              <div class="spinner-border mt-2" role="status" v-if="isLoading">
                <span class="sr-only">Loading...</span>
              </div>
              <div v-if="getFactureLibreErroresTh" class="error">
                <ul v-if="Array.isArray(getFactureLibreErroresTh)" class="mb-0">
                  <li
                    v-for="(e, index) in getFactureLibreErroresTh"
                    :key="index"
                  >
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ getFactureLibreErroresTh }}</div>
              </div>
            </div>
            <div class="box-end-position-libre">
              <search
                :value="num"
                @changeSearchValue="changeSearchValue"
                :dynamic="false"
              ></search>
              <router-link
                class="
                  button-export-style
                  style-btn-add
                  ml-2
                  mt-1
                  mb-1
                  add-facture-style-libre
                "
                to="/add-facture"
                title="Nouvelle Facture"
                v-if="checkPermission('FLCNF')"
              >
                <font-awesome-icon icon="file-medical" class="file-medical"
              /></router-link>
              <b-button
                class="button-export-style add-facture-style-libre ml-2"
                title="Vider l'ordre"
                @click="refrech()"
                v-if="checkPermission('FLVO')"
              >
                <font-awesome-icon icon="retweet"
              /></b-button>
              <div v-if="getLoadingMailFactureLibre" class="loading ml-2">
                <div class="spinner-border loadingFetch" role="status"></div>
              </div>
              <sendMail
                v-if="
                  ((computedCheckedRows && computedCheckedRows.length > 0) ||
                    computedGetDataToUse.length > 0) &&
                    checkPermission('FLEES')
                "
                :dataToUse="
                  vueToDisplay === true
                    ? computedCheckedRows
                    : computedGetDataToUse
                "
                @eventMailSendRefreshTable="refrech()"
              />
              <reglement
                class="mr-1 ml-1"
                v-if="
                  checkPermission('FLRG') &&
                    computedCheckedRowsOneClient &&
                    computedTotal.someApaye > 0
                "
                :dataToUse="computedCheckedRowsOneClientFacture"
                interfaceModal="ReglementFactureRowLibre"
              />
              <export-dynamic
                v-if="checkPermission('FLTZIP') || checkPermission('FLXLS')"
                :filter="ComputedFilter"
                :selected="
                  vueToDisplay === true
                    ? computedCheckRowsexportXls
                    : computedCheckRows
                "
                :exportXls="exportFactureLibre"
                :exportZip="downloadZIP"
                :famille="libre"
                :zipRequired="checkPermission('FLTZIP')"
                :xlsRequired="checkPermission('FLXLS')"
              />
              <b-button
                size="sm"
                class="button-export-style add-facture-style-libre mr-2"
                title="Filter"
                id="popover-target-1"
              >
                <font-awesome-icon icon="filter" />
              </b-button>
              <b-popover
                triggers="focus"
                target="popover-target-1"
                placement="top"
                custom-class="my-custom-class-popover-filter"
                ><filter-component
                  label="Type"
                  v-if="checkPermission('FLFT')"
                  classLabel="w-100-px"
                >
                  <template v-slot:body>
                    <multiselect
                      v-model="type"
                      :options="typeFacture"
                      :multiple="true"
                      label="text"
                      track-by="value"
                      class="customSelectMultipleFilter"
                      :showLabels="false"
                      placeholder="sélectionner"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      @input="selectAllType()"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="
                            values.length && values.some(e => e.text != 'Tous')
                          "
                          >{{ values.length }} options selected</span
                        >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="values.some(e => e.text == 'Tous')"
                          >Tous selectionnée</span
                        >
                      </template>
                    </multiselect>
                  </template>
                </filter-component>
                <filter-component
                  label="Etat"
                  v-if="checkPermission('FLFE')"
                  classLabel="w-100-px mt-1"
                >
                  <template v-slot:body>
                    <multiselect
                      v-model="etat"
                      :options="etatListFilter"
                      :multiple="true"
                      label="text"
                      track-by="value"
                      class="customSelectMultipleFilter mt-1"
                      :showLabels="false"
                      placeholder="sélectionner"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      @input="selectAllType()"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="
                            values.length && values.some(e => e.text != 'Tous')
                          "
                          >{{ values.length }} options selected</span
                        >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="values.some(e => e.text == 'Tous')"
                          >Tous selectionnée</span
                        >
                      </template>
                    </multiselect>
                  </template>
                </filter-component>
                <filter-component
                  label="Mode de règlement"
                  v-if="checkPermission('FLFMR')"
                  classLabel="w-100-px mt-1"
                >
                  <template v-slot:body>
                    <multiselect
                      v-model="mode_reglement"
                      :options="computedTypeReglement"
                      :multiple="true"
                      label="text"
                      track-by="value"
                      class="customSelectMultipleFilter mt-1"
                      :showLabels="false"
                      placeholder="sélectionner"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      @input="selectAllType()"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="
                            values.length && values.some(e => e.text != 'Tous')
                          "
                          >{{ values.length }} options selected</span
                        >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="values.some(e => e.text == 'Tous')"
                          >Tous selectionnée</span
                        >
                      </template>
                    </multiselect>
                  </template>
                </filter-component>
                <filter-component
                  label="Société"
                  v-if="checkPermission('FLFV')"
                  classLabel="w-100-px mt-1"
                >
                  <template v-slot:body>
                    <multiselect
                      v-model="vendeur"
                      :options="[
                        { text: 'Tous', value: null },
                        ...computedGetAcheteurs
                      ]"
                      :multiple="true"
                      label="text"
                      track-by="value"
                      class="customSelectMultipleFilter mt-1"
                      :showLabels="false"
                      placeholder="sélectionner"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      @input="selectAllType()"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="
                            values.length && values.some(e => e.text != 'Tous')
                          "
                          >{{ values.length }} Société</span
                        >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="values.some(e => e.text == 'Tous')"
                          >Tous selectionnée</span
                        >
                      </template>
                    </multiselect>
                  </template>
                </filter-component>
                <filter-component
                  label="Client"
                  v-if="checkPermission('FLFA')"
                  classLabel="w-100-px mt-1"
                >
                  <template v-slot:body>
                    <multiselect
                      v-model="acheteur"
                      :options="[
                        { text: 'Tous', value: null },
                        ...computedGetAcheteurs
                      ]"
                      :multiple="true"
                      label="text"
                      track-by="value"
                      class="customSelectMultipleFilter mt-1"
                      :showLabels="false"
                      placeholder="sélectionner"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      @input="selectAllType()"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="
                            values.length && values.some(e => e.text != 'Tous')
                          "
                          >{{ values.length }} Client</span
                        >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="values.some(e => e.text == 'Tous')"
                          >Tous selectionnée</span
                        >
                      </template>
                    </multiselect>
                  </template>
                </filter-component>
                <filter-component
                  label="Catégorie"
                  v-if="checkPermission('FLFC')"
                  classLabel="w-100-px mt-1"
                >
                  <template v-slot:body>
                    <multiselect
                      v-model="categorie"
                      :options="[
                        { text: 'Tous', value: null },
                        ...computeGetcategories
                      ]"
                      :multiple="true"
                      label="text"
                      track-by="value"
                      class="customSelectMultipleFilter mt-1"
                      :showLabels="false"
                      placeholder="sélectionner"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      :preselect-first="false"
                      @input="selectAllCategory()"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                      >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="
                            values.length && values.some(e => e.text != 'Tous')
                          "
                          >{{ values.length }} options selected</span
                        >
                        <span
                          :class="{
                            'multiselect__single showBlock': !isOpen,
                            'multiselect__single ': isOpen
                          }"
                          v-if="values.some(e => e.text == 'Tous')"
                          >Tous selectionnée</span
                        >
                      </template>
                    </multiselect>
                  </template>
                </filter-component>
              </b-popover>
              <button
                class="
                  confirm-button-style
                  button-export-style
                  add-facture-style-libre
                "
                title="Comptabiliser facture"
                v-if="
                  (computedCheck || factureAvoirChecked.length > 0) &&
                    checkPermission('FLC')
                "
                @click.prevent.stop="FacuresToConfirme()"
              >
                <font-awesome-icon icon="check-double" />
              </button>
            </div>
            <!-- <h5>Revenus / Tous</h5> -->
          </div>
        </div>
        <!-- block filtre -->
        <div>
          <div
            v-if="
              date_debut !== null ||
                date_fin !== null ||
                num !== null ||
                // vueToDisplay !== null ||
                getShowNotifExportFile == true
            "
            class="block-filter-style"
          >
            <span class="phrase-style">Les filtres choisie sont : </span>
            <span class="item-filter-style" v-if="date_debut !== null"
              >Date début : {{ date_debut }}</span
            >
            <span class="item-filter-style" v-if="date_fin !== null"
              >Date fin : {{ date_fin }}</span
            >
            <span v-if="num !== null" class="item-filter-style"
              >Recherche : {{ num }}</span
            >
            <span v-if="vueToDisplay !== null" class="item-filter-style"
              >Vue à afficher :
              {{ vueToDisplay == false ? 'Par défaut' : 'Client' }}</span
            >
            <div class="notif-export-file" v-if="getShowNotifExportFile">
              Préparation du fichier en cours
            </div>
          </div>
        </div>
        <!--End block filtre -->
        <div
          class="chip-filters-simulation"
          v-if="
            type.length ||
              etat.length ||
              mode_reglement.length ||
              vendeur.length ||
              acheteur.length ||
              categorie.length
          "
        >
          <div v-if="type && type.length" class="block-filter">
            <span class="title-block-chip">Type document </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="type"
              @input="handleFiltre"
            >
              <vs-chip
                :key="tp.text + 'master-filiale'"
                @click="remove(tp, 'type', 'handleFiltre')"
                v-for="tp in type"
                closable
              >
                {{ tp.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="etat && etat.length" class="block-filter">
            <span class="title-block-chip">Etat </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="etat"
              @input="handleFiltre"
            >
              <vs-chip
                :key="et.text + 'master-filiale'"
                @click="remove(et, 'etat', 'handleFiltre')"
                v-for="et in etat"
                closable
              >
                {{ et.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div
            v-if="mode_reglement && mode_reglement.length"
            class="block-filter"
          >
            <span class="title-block-chip">Mode règlement </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="mode_reglement"
              @input="handleFiltre"
            >
              <vs-chip
                :key="mode.text + 'master-filiale'"
                @click="remove(mode, 'mode_reglement', 'handleFiltre')"
                v-for="mode in mode_reglement"
                closable
              >
                {{ mode.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="vendeur && vendeur.length" class="block-filter">
            <span class="title-block-chip">Société </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="vendeur"
              @input="handleFiltre"
            >
              <vs-chip
                :key="vd.text + 'master-filiale'"
                @click="remove(vd, 'vendeur', 'handleFiltre')"
                v-for="vd in vendeur"
                closable
              >
                {{ vd.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="acheteur && acheteur.length" class="block-filter">
            <span class="title-block-chip">client </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="acheteur"
              @input="handleFiltre"
            >
              <vs-chip
                :key="ach.text + 'master-filiale'"
                @click="remove(ach, 'acheteur', 'handleFiltre')"
                v-for="ach in acheteur"
                closable
              >
                {{ ach.text }}
              </vs-chip>
            </vs-chips>
          </div>
          <div v-if="categorie && categorie.length" class="block-filter">
            <span class="title-block-chip">client </span>
            <vs-chips
              color="rgb(145, 32, 159)"
              placeholder="New Element"
              v-model="categorie"
              @input="handleFiltre"
            >
              <vs-chip
                :key="cat.text + 'master-filiale'"
                @click="remove(cat, 'categorie', 'handleFiltre')"
                v-for="cat in categorie"
                closable
              >
                {{ cat.text }}
              </vs-chip>
            </vs-chips>
          </div>
        </div>

        <b-table
          :style="sizeTable"
          v-if="vueToDisplay === false"
          show-empty
          id="my-table"
          class="
            custom-table-style
            customTableFactureLibreVueDefault
            table-header
          "
          :fields="computedFildsWithPermission"
          :items="getFacturesLibresTh"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template class="check-th" v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                v-model="checkAll"
                @change="checkAllFunction"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
            <div
              v-else
              :class="{
                'd-flex justify-content-center align-items-center':
                  data.field.isSortable === true,
                'd-flex justify-content-center':
                  data.field.isSortable === false,
                'underline-selected-colomn': data.field.key === column
              }"
            >
              {{ data.field.label }}

              <font-awesome-icon
                v-if="data.field.isSortable === true && checkPermission('FLOC')"
                @click="listOrder(data.field.key, 'ASC')"
                icon="arrow-up"
                :class="
                  data.field.key === column && order === 'ASC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
              {{ ' ' }}
              <font-awesome-icon
                @click="listOrder(data.field.key, 'DESC')"
                v-if="data.field.isSortable === true && checkPermission('FLOC')"
                icon="arrow-down"
                :class="
                  data.field.key === column && order === 'DESC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
            </div>
          </template>
          <template v-slot:cell(check_all)="data"
            >{{ data.item.check_all }}
            <b-form-checkbox
              v-model="data.item.check"
              @change="checkOneFunction(data.item.check)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data" class="col-check-obligee">
            <button
              class="btn btn-anomalie"
              @click="data.toggleDetails"
              v-if="
                data &&
                  data.item &&
                  data.item.FactureAvoir &&
                  data.item.FactureAvoir.length > 0
              "
            >
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
                title="Afficher les avoirs"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
                title="Masques les avoirs"
              />
            </button>
          </template>
          <template v-slot:cell(pices-icon)="data" class="col-check-obligee">
            <font-awesome-icon
              v-if="
                data && data.item && data.item.files && data.item.files.length
              "
              icon="file-code"
              class="icon-pieces-exist-style-color ml-1"
            />
          </template>
          <template #row-details="data">
            <span
              v-if="
                data &&
                  data.item &&
                  data.item.FactureAvoir &&
                  data.item.FactureAvoir.length === 0
              "
              >Pas des factures avoir pour cette facture
            </span>
            <span
              v-else
              v-for="item in data.item.FactureAvoir"
              :key="item.id"
              :class="
                item.motif_avoir === 'avoir_global'
                  ? 'couleur-facture-global align-td-item border-botom'
                  : 'align-td-item border-botom'
              "
              class="align-td-item background-week"
            >
              <td class="item-details-style col-check-obligee">
                <div>
                  <b-form-checkbox
                    v-model="item.checkAvoir"
                    @change="checkOneAvoirFunction(item)"
                    class="check-th"
                    :value="true"
                    :unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </td>
              <td class="item-details-style col-plus-obligee">
                <span> </span>
              </td>
              <td class="item-details-style col-plus-obligee">
                <span> </span>
              </td>
              <td v-if="checkPermission('FLACTD')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.type }}
                </div>
              </td>
              <td v-if="checkPermission('FLACS')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.vendeur }}
                </div>
              </td>
              <td v-if="checkPermission('FLACN')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.num }}
                </div>
              </td>
              <td v-if="checkPermission('FLACDC')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_creation }}
                </div>
              </td>
              <td v-if="checkPermission('FLACC')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.acheteur }}
                </div>
              </td>
              <td v-if="checkPermission('FLACRAP')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  -
                </div>
              </td>
              <td v-if="checkPermission('FLACE')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ data.item.etat == 'Annulée' ? 'Verrouillée' : '-' }}
                </div>
              </td>
              <td v-if="checkPermission('FLACNHT')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_net_ht }} €
                </div>
              </td>
              <td v-if="checkPermission('FLACTVA')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_tva }} €
                </div>
              </td>
              <td
                v-if="checkPermission('FLACTTTC')"
                class="fix-margin-td-avoir"
              >
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.total_ttc }} €
                </div>
              </td>
              <td v-if="checkPermission('FLADP')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.date_paiement ? item.date_paiement : '-' }}
                </div>
              </td>
              <td v-if="checkPermission('FLARG')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  -
                </div>
              </td>
              <td v-if="checkPermission('FLACSC')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ data.item.statut_client }}
                </div>
              </td>
              <td v-if="checkPermission('FLACCAT')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{
                    data.item.categorie && data.item.categorie.nom_categorie
                      ? data.item.categorie.nom_categorie
                      : '-'
                  }}
                </div>
              </td>
              <td
                v-if="checkPermission('FLACCONF')"
                class="fix-margin-td-avoir"
              >
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.confirme === true ? 'OUI' : 'NON' }}
                </div>
              </td>
              <!-- <td v-if="checkPermission('FLACRP')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  -
                </div>
              </td> -->
              <td v-if="checkPermission('FLACCP')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  {{ item.user ? item.user : '-' }}
                </div>
              </td>
              <td v-if="checkPermission('FLACRP')" class="fix-margin-td-avoir">
                <div class="block-service-style d-flex justify-content-center">
                  -
                </div>
              </td>
              <td class="fix-margin-td-avoir actions-avoir">
                <div
                  class="block-service-style d-flex justify-content-center m-1"
                >
                  <button
                    :id="`popover-target-${item.id}`"
                    type="button"
                    class="btn btn-secondary bars"
                  >
                    <font-awesome-icon icon="bars" />
                  </button>
                  <b-popover
                    :target="`popover-target-${item.id}`"
                    :triggers="computedPopover"
                    placement="bottom"
                    :custom-class="computedStyle"
                  >
                    <b-button
                      class="button-no-style"
                      title="Afficher Facture"
                      v-if="checkPermission('FLAF')"
                      @click.prevent.stop="visualisation(item)"
                    >
                      Afficher facture
                    </b-button>
                    <br />
                    <b-button
                      v-if="
                        checkPermission('FLMF') &&
                          item.motif_avoir !== 'avoir_global'
                      "
                      class="button-no-style"
                      title="Modifier Facture"
                      @click.prevent.stop="
                        onClickNavigate(data.item.id, 'Modification')
                      "
                    >
                      Modifier facture
                    </b-button>

                    <b-button
                      v-if="checkPermission('FLTFP')"
                      @click.prevent.stop="FacuresToDownloadOnePDF(item)"
                      class="button-no-style"
                      title="Télécharger Facture"
                    >
                      <div
                        :class="{
                          'd-flex': item.downloadPDF,
                          'justify-content-center': item.downloadPDF
                        }"
                      >
                        Télécharger facture
                        <div
                          v-if="item.downloadPDF"
                          class="loading-download ml-2"
                        >
                          <div class="spinner-border" role="status"></div>
                        </div>
                      </div>
                    </b-button>
                    <Historique
                      :awesome="false"
                      :permission="checkPermission('FLHFAVPD')"
                      :index="item.id"
                      :dataToUse="item.historique"
                      @returnHistorique="returnHistorique"
                      :styleMenuBtn="true"
                    />
                  </b-popover>
                </div>
              </td>
            </span>
          </template>

          <template v-if="checkPermission('FLACTD')" v-slot:cell(type)="data">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(Vendeur)="data">
            {{ data.item.vendeur }}
          </template>
          <template v-slot:cell(reste)="data">
            {{ data.item.reste }} €
          </template>

          <template v-slot:cell(num)="data">
            {{ data.item.num }}
          </template>
          <template v-slot:cell(total_net_ht)="data">
            {{ data.item.total_net_ht }} €
          </template>
          <template v-slot:cell(total_tva)="data">
            {{ data.item.total_tva }} €
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ data.item.total_ttc }} €
          </template>
          <template v-slot:cell(User)="data">
            {{ data.item.user }}
          </template>
          <template v-slot:cell(statut_client)="data">
            <div
              class="custom-component-etat-facture-libre"
              :class="{ open: data.item.openSelectClient == true }"
              v-click-outside="closeSelectEtatClient"
              :id="'component' + data.index"
            >
              <div
                :id="'etat-facture-' + data.item.id"
                class="b-form-select-custom-etat"
                @click="setVisibilityEtatClientList(data.item, data.index)"
                :class="{
                  'red-color-custom-select':
                    data.item.statut_client == 'Payé en partie',
                  'white-color-custom-select':
                    data.item.statut_client == 'A payer',
                  'green-color-custom-select': data.item.statut_client == 'Payé'
                }"
              >
                <div id="content">
                  {{ data.item.statut_client }}
                  <font-awesome-icon
                    icon="caret-down"
                    class="icon-class-caret-down"
                  />
                </div>
                <div
                  id="background"
                  :class="{
                    'white-color-custom-select':
                      data.item.statut_client == 'A payer',
                    'green-color-custom-select':
                      data.item.statut_client == 'Payé' ||
                      data.item.statut_client == 'Payé en partie'
                  }"
                  :style="computedWidth(data.item)"
                ></div>
              </div>
              <ul
                class="dropdown-menu pull-right"
                role="menu"
                v-if="
                  data.item.openSelectClient == true &&
                    data.item.statut_client != 'Annulée' &&
                    data.item.statut_client != 'Créé' &&
                    data.item.etat === 'A payer' &&
                    (filialConnected === data.item.acheteur ||
                      filialConnected === null) &&
                    checkPermission('FLMESC') &&
                    false
                "
              >
                <li
                  v-for="result in etatList"
                  :key="result.text + data.item.id"
                  @click="updateEtatPaimentClient(data.item, result)"
                >
                  {{ result.text }}
                </li>
              </ul>
            </div>
          </template>
          <template v-slot:cell(etat)="data">
            <div
              class="custom-component-etat-facture-libre"
              :class="{ open: data.item.openSelect == true }"
              v-click-outside="closeSelectEtat"
              :id="'component' + data.index"
            >
              <div
                :id="'etat-facture-' + data.item.id"
                class="b-form-select-custom-etat"
                @click="setVisibilityEtatList(data.item, data.index)"
                :class="{
                  'red-color-custom-select': data.item.etat == 'Payé en partie',
                  'white-color-custom-select': data.item.etat == 'A payer',
                  'green-color-custom-select': data.item.etat == 'Payé'
                }"
              >
                <div id="content">
                  {{ data.item.etat }}
                  <font-awesome-icon
                    icon="caret-down"
                    class="icon-class-caret-down"
                  />
                </div>
                <div
                  id="background"
                  :class="{
                    'white-color-custom-select': data.item.etat == 'A payer',
                    'green-color-custom-select':
                      data.item.etat == 'Payé' ||
                      data.item.etat == 'Payé en partie'
                  }"
                  :style="computedWidth(data.item)"
                ></div>
              </div>
              <ul
                class="dropdown-menu pull-right"
                role="menu"
                v-if="
                  data.item.openSelect == true &&
                    data.item.etat != 'Annulée' &&
                    data.item.etat != 'Créé' &&
                    (filialConnected === data.item.vendeur ||
                      filialConnected === null) &&
                    checkPermission('FLMES') &&
                    false
                "
              >
                <li
                  v-for="result in etatList"
                  :key="result.text + data.item.id"
                  @click="updateEtat(data.item, result)"
                >
                  {{ result.text }}
                </li>
              </ul>
            </div>
          </template>
          <template v-slot:cell(Acheteur)="data">
            {{ data.item.acheteur }}
          </template>
          <template v-slot:cell(date_creation)="data">
            {{ data.item.date_creation | formateDate }}
          </template>
          <template v-slot:cell(date_paiement)="data">
            {{
              data.item && data.item.date_paiement
                ? data.item.date_paiement
                : '-'
            }}
          </template>
          <template v-slot:cell(reglement)="data">
            {{ data.item.montant_regle ? data.item.montant_regle + '€' : '-' }}
            <font-awesome-icon
              v-show="
                data.item && data.item.reglements && data.item.reglements.length
              "
              :id="`popover-1-${data.index}`"
              icon="info"
              class="info-icon-commercial-lot content-tooltip-style w-25"
            />

            <b-popover
              :target="`popover-1-${data.index}`"
              :triggers="computedPopoverTriggers"
              :custom-class="'avoir-tooltip ' + stylePopover"
            >
              <b-table-simple class="table-fiche">
                <b-tbody>
                  <b-tr class="title-ligne">
                    <b-th class="newcolor">#</b-th>
                    <b-th class="newcolor">Montant reglé</b-th>
                    <b-th class="newcolor">Date de paiement</b-th>
                    <b-th class="newcolor">Type de paiement</b-th>
                    <b-th
                      class="newcolor"
                      v-if="checkPermission('FLURG')"
                    ></b-th>
                  </b-tr>
                  <template v-for="(reglement, i) in data.item.reglements">
                    <b-tr :key="'reglement' + i">
                      <b-td class="newcolor">{{ reglement.payment_id }} </b-td>
                      <b-td class="newcolor">{{ reglement.amount }} €</b-td>
                      <b-td class="newcolor">{{
                        formateDateGetters(reglement.payment_date)
                      }}</b-td>
                      <b-td class="newcolor">{{ reglement.payment_type }}</b-td>
                      <b-td class="newcolor" v-if="checkPermission('FLURG')"
                        ><update-reglement-modal
                          :item="reglement"
                          dataInterface="libre"
                          @openModal="triggersPop = 'click'"
                          @closeModal="triggersPop = 'hover'"
                          :grouped="false"
                      /></b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </template>
          <template v-slot:cell(categorie)="data">
            {{ data.item.categorie ? data.item.categorie : '-' }}
          </template>
          <template v-slot:cell(confirme)="data">
            {{ data.item.confirme === true ? 'OUI' : 'NON' }}
          </template>
          <template v-slot:cell(num_proforma)="data">
            {{
              data.item && data.item.num_proforma ? data.item.num_proforma : '-'
            }}
          </template>
          <template v-slot:cell(facture)> </template>
          <template v-slot:cell(action)="data">
            <button
              :id="`popover-target-${data.item.id}`"
              type="button"
              class="btn btn-secondary bars"
            >
              <font-awesome-icon icon="bars" />
            </button>
            <b-popover
              :target="`popover-target-${data.item.id}`"
              :triggers="computedPopover"
              placement="bottom"
              :custom-class="computedStyle"
            >
              <b-button
                v-if="checkPermission('FLAF')"
                class="button-no-style"
                title="Afficher Facture"
                @click.prevent.stop="visualisation(data.item)"
              >
                Afficher facture
              </b-button>

              <b-button
                v-if="
                  (data.item.user_id === idConnecter ||
                    computeVendeursNames.includes(data.item.vendeur)) &&
                    ((checkPermission('FLMF') &&
                      data.item.confirme === false &&
                      data.item.type != 'Facture d\'avoir' &&
                      (data.item.etat === 'Créé' ||
                        data.item.etat === 'A payer') &&
                      data.item &&
                      data.item.FactureAvoir &&
                      data.item.FactureAvoir.length === 0) ||
                      (data.item.type == 'Facture d\'avoir' &&
                        data.item.motif_avoir == 'ristourne' &&
                        checkPermission('FLMFFC')))
                "
                class="button-no-style"
                title="Modifier Facture"
                @click.prevent.stop="
                  onClickNavigate(data.item.id, 'Modification')
                "
              >
                Modifier facture
              </b-button>
              <b-button
                v-if="checkPermission('FLTFP')"
                class="button-no-style"
                title="Télécharger Facture"
                @click.prevent.stop="FacuresToDownloadOnePDF(data.item)"
              >
                <div
                  :class="{
                    'd-flex': data.item.downloadPDF,
                    'justify-content-center': data.item.downloadPDF
                  }"
                >
                  Télécharger facture
                  <div
                    v-if="data.item.downloadPDF"
                    class="loading-download ml-2"
                  >
                    <div class="spinner-border" role="status"></div>
                  </div>
                </div>
              </b-button>
              <b-button
              v-if="  data.item.type !== 'Facture d\'avoir' &&
                    checkPermission('FLDF') &&
                    data.item &&
                    data.item.FactureAvoir &&
                    !data.item.FactureAvoir.some(
                      e => e.motif_avoir === 'avoir_global'
                    )"
                class="button-no-style"
                title="Dupliquer Facture"
                @click.prevent.stop="
                  onClickNavigate(data.item.id, 'Duplication')
                "
              >
            
                Dupliquer facture
              </b-button>
              <b-button
                class="button-no-style"
                title="Avoir sur Facture"
                v-if="
                  ((checkPermission('FLAVF') &&
                    (data.item.user_id === idConnecter ||
                      roleConnecter === 'admin')) ||
                    checkPermission('FLCAPT')) &&
                    data.item.FactureAvoir.length === 0 &&
                    data.item.type !== 'Facture d\'avoir' &&
                    data.item.type !== 'Facture proforma'
                "
                @click.prevent.stop="openConfirmAvoirModal(data.item)"
              >
                Avoir sur facture
              </b-button>

              <b-button
                class="button-no-style"
                title="Transformer vers Facture"
                v-if="data.item.type === 'Facture proforma'"
                @click.prevent.stop="trasformFacture(data.item)"
              >
                <div
                  :class="{
                    'd-flex': data.item.transferePro,
                    'justify-content-center': data.item.transferePro
                  }"
                >
                  Transformer vers facture
                  <div
                    v-if="data.item.transferePro"
                    class="loading-download ml-2"
                  >
                    <div class="spinner-border" role="status"></div>
                  </div>
                </div>
              </b-button>
              <br />
              <b-button
                v-if="
                  checkPermission('FLPJVPD') &&
                    data.item.type !== 'Facture d\'avoir'
                "
                class="button-no-style"
                title="Pièce jointes"
                @click.prevent.stop="handleFichiers(data.item)"
              >
                Pièce jointes
              </b-button>
              <Historique
                :awesome="false"
                :permission="checkPermission('FLHFVPD')"
                :index="data.item.id"
                :dataToUse="data.item.historique"
                @returnHistorique="returnHistorique"
                :styleMenuBtn="true"
              />
            </b-popover>
          </template>
        </b-table>

        <b-table
          :style="sizeTable"
          v-if="vueToDisplay === true"
          ref="table"
          show-empty
          id="my-table"
          class="
            custom-table-style
            table-appel-paiement-obligee-th-vue-cilent table-header-regie
          "
          :items="getFacturesLibresTh"
          :fields="computedFildsVueClientWithPermission"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template class="check-th" v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                v-model="checkAll"
                @change="checkAllFunctionClienVue"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(check_all)="data"
            >{{ data.item.check_all }}
            <b-form-checkbox
              v-model="data.item.checkClient"
              @change="checkAllVerif(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn btn-anomalie" @click="getDataForOneRow(data)">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                title="Afficher les avoirs"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                title="Masques les avoirs"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(pices-icon)=""> - </template>
          <template v-slot:cell(Acheteur)="data">
            {{ data.item.name }} ({{ data.item.count }})
          </template>
          <template v-slot:cell(type)=""> - </template>
          <template v-slot:cell(Vendeur)=""> - </template>
          <template v-slot:cell(num)=""> - </template>
          <template v-slot:cell(date_creation)=""> - </template>
          <template v-slot:cell(etat)=""> - </template>
          <template v-slot:cell(reste)=""> - </template>
          <template v-slot:cell(total_net_ht)="data">
            {{ data.item.total_ht }} €
          </template>
          <template v-slot:cell(total_tva)="data">
            {{ data.item.total_tva }} €
          </template>
          <template v-slot:cell(total_ttc)="data">
            {{ data.item.total_ttc }} €
          </template>
          <template v-slot:cell(date_paiement)=""> - </template>
          <template v-slot:cell(reglement)=""> - </template>
          <template v-slot:cell(categorie)=""> - </template>
          <template v-slot:cell(statut_client)=""> - </template>

          <template v-slot:cell(confirme)=""> - </template>
          <template v-slot:cell(User)=""> - </template>
          <template v-slot:cell(num_proforma)=""> - </template>
          <template v-slot:cell(action)=""> - </template>
          <template #row-details="data">
            <template
              v-if="
                data.item.loadingDetailt &&
                  data.item &&
                  data.item.factures &&
                  data.item.factures.length === 0
              "
            >
              <circles-to-rhombuses-spinner
                :animation-duration="1500"
                :size="50"
                :color="'#ff1d5e'"
                class="loading-details-frais"
              />
            </template>
            <span
              v-for="(item, index) in data.item.factures"
              :key="data.item.name + index"
            >
              <template>
                <span class="align-td-item border-botom background-week">
                  <td class="item-details-style col-check-obligee">
                    <b-form-checkbox
                      v-model="item.check"
                      @change="checkAllVerifDetails(data.item, item)"
                      class="check-th-details ml-2"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </td>
                  <td class="item-details-style col-plus-obligee">
                    <button
                      class="btn btn-anomalie"
                      @click="item.showDetails = !item.showDetails"
                      :disabled="item.FactureAvoir.length > 0 ? false : true"
                    >
                      <font-awesome-icon
                        class="icon-plus-double"
                        icon="angle-right"
                        v-if="item.showDetails == false"
                      />
                      <font-awesome-icon
                        class="icon-plus-double"
                        icon="angle-down"
                        v-if="item.showDetails == true"
                      />
                    </button>
                  </td>
                  <td class="item-details-style col-plus-obligee">
                    <font-awesome-icon
                      v-if="item && item.files && item.files.length"
                      icon="file-code"
                      class="icon-pieces-exist-style-color"
                    />
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACC')"
                  >
                    {{ item.acheteur }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACTD')"
                  >
                    {{ item.type }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACS')"
                  >
                    {{ item.vendeur }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACN')"
                  >
                    {{ item.num }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACDC')"
                  >
                    {{ item.date_creation | formateDate }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACRAP')"
                  >
                    {{ item.reste }} €
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACE')"
                  >
                    <div
                      class="custom-component-etat-facture-libre"
                      :class="{ open: item.openSelect == true }"
                      v-click-outside="closeSelectEtat"
                      :id="'component' + index"
                    >
                      <div
                        :id="'etat-facture-' + item.id"
                        class="b-form-select-custom-etat"
                        @click="setVisibilityEtatList(item, index)"
                        :class="{
                          'red-color-custom-select':
                            item.etat == 'Payé en partie',
                          'white-color-custom-select': item.etat == 'A payer',
                          'green-color-custom-select': item.etat == 'Payé'
                        }"
                      >
                        <div id="content">
                          {{ item.etat }}
                          <font-awesome-icon
                            icon="caret-down"
                            class="icon-class-caret-down"
                          />
                        </div>
                        <div
                          id="background"
                          :class="{
                            'white-color-custom-select': item.etat == 'A payer',
                            'green-color-custom-select':
                              item.etat == 'Payé' ||
                              item.etat == 'Payé en partie'
                          }"
                          :style="computedWidth(item)"
                        ></div>
                      </div>
                      <ul
                        class="dropdown-menu pull-right"
                        role="menu"
                        v-if="
                          item.openSelect == true &&
                            item.etat != 'Annulée' &&
                            item.etat != 'Créé' &&
                            (filialConnected === item.vendeur ||
                              filialConnected === null) &&
                            checkPermission('FLMES') &&
                            false
                        "
                      >
                        <li
                          v-for="result in etatList"
                          :key="result.text + item.id"
                          @click="updateEtat(item, result)"
                        >
                          {{ result.text }}
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACNHT')"
                  >
                    {{ item.total_net_ht }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACTVA')"
                  >
                    {{ item.total_tva }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACTTTC')"
                  >
                    {{ item.total_ttc }} €
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLADP')"
                  >
                    {{ item.date_paiement }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLARG')"
                  >
                    {{ item.montant_regle }} €
                    <font-awesome-icon
                      v-show="item.reglements.length"
                      :id="`popover-1-${index}`"
                      icon="info"
                      class="
                        info-icon-commercial-lot
                        content-tooltip-style
                        w-25
                      "
                    />

                    <b-popover
                      :target="`popover-1-${index}`"
                      :triggers="computedPopoverTriggers"
                      :custom-class="'avoir-tooltip' + stylePopover"
                    >
                      <b-table-simple class="table-fiche">
                        <b-tbody>
                          <b-tr class="title-ligne">
                            <b-th class="newcolor">#</b-th>
                            <b-th class="newcolor">Montant reglé</b-th>
                            <b-th class="newcolor">Date de paiement</b-th>
                            <b-th class="newcolor">Type de paiement</b-th>
                            <b-th
                              class="newcolor"
                              v-if="checkPermission('FLURG')"
                            ></b-th>
                          </b-tr>
                          <template v-for="(reglement, i) in item.reglements">
                            <b-tr :key="'reglement' + i">
                              <b-td class="newcolor">{{
                                reglement.payment_id
                              }}</b-td>
                              <b-td class="newcolor"
                                >{{ reglement.amount }} €</b-td
                              >
                              <b-td class="newcolor">{{
                                formateDateGetters(reglement.payment_date)
                              }}</b-td>
                              <b-td class="newcolor">{{
                                reglement.payment_type
                              }}</b-td>
                              <b-td
                                class="newcolor"
                                v-if="checkPermission('FLURG')"
                                ><update-reglement-modal
                                  :item="reglement"
                                  dataInterface="libre"
                                  @openModal="triggersPop = 'click'"
                                  @closeModal="triggersPop = 'hover'"
                              /></b-td>
                            </b-tr>
                          </template>
                        </b-tbody>
                      </b-table-simple>
                    </b-popover>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACSC')"
                  >
                    <div
                      class="custom-component-etat-facture-libre"
                      :class="{ open: item.openSelectClient == true }"
                      v-click-outside="closeSelectEtatClient"
                      :id="'component' + index"
                    >
                      <div
                        :id="'etat-facture-' + item.id"
                        class="b-form-select-custom-etat"
                        @click="setVisibilityEtatClientList(item, index)"
                        :class="{
                          'red-color-custom-select':
                            item.statut_client == 'Payé en partie',
                          'white-color-custom-select':
                            item.statut_client == 'A payer',
                          'green-color-custom-select':
                            item.statut_client == 'Payé'
                        }"
                      >
                        <div id="content">
                          {{ item.statut_client }}
                          <font-awesome-icon
                            icon="caret-down"
                            class="icon-class-caret-down"
                          />
                        </div>
                        <div
                          id="background"
                          :class="{
                            'white-color-custom-select':
                              item.statut_client == 'A payer',
                            'green-color-custom-select':
                              item.statut_client == 'Payé' ||
                              item.statut_client == 'Payé en partie'
                          }"
                          :style="computedWidth(item)"
                        ></div>
                      </div>
                      <ul
                        class="dropdown-menu pull-right"
                        role="menu"
                        v-if="
                          item.openSelectClient == true &&
                            item.statut_client != 'Annulée' &&
                            item.statut_client != 'Créé' &&
                            item.etat === 'A payer' &&
                            (filialConnected === item.acheteur ||
                              filialConnected === null) &&
                            checkPermission('FLMESC') &&
                            false
                        "
                      >
                        <li
                          v-for="result in etatList"
                          :key="result.text + item.id"
                          @click="updateEtatPaimentClient(item, result)"
                        >
                          {{ result.text }}
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACCAT')"
                  >
                    {{ item.categorie }}
                  </td>

                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACCONF')"
                  >
                    {{ item.confirme === true ? 'OUI' : 'NON' }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACCP')"
                  >
                    {{ item.user }}
                  </td>
                  <td
                    class="item-details-style"
                    v-if="checkPermission('FLACRP')"
                  >
                    {{ item && item.num_proforma ? item.num_proforma : '-' }}
                  </td>
                  <td class="item-details-style actions-facture">
                    <button
                      :id="`popover-target-${item.id}`"
                      type="button"
                      class="btn btn-secondary bars"
                    >
                      <font-awesome-icon icon="bars" />
                    </button>
                    <b-popover
                      :target="`popover-target-${item.id}`"
                      :triggers="computedPopover"
                      placement="bottom"
                      :custom-class="computedStyle"
                    >
                      <b-button
                        v-if="checkPermission('FLAF')"
                        class="button-no-style"
                        title="Afficher Facture"
                        @click.prevent.stop="visualisation(item)"
                      >
                        Afficher facture
                      </b-button>
                      <b-button
                        v-if="
                          (item.user_id === idConnecter ||
                            computeVendeursNames.includes(item.vendeur)) &&
                            ((checkPermission('FLMF') &&
                              item.confirme === false &&
                              item.type != 'Facture d\'avoir' &&
                              (item.etat === 'Créé' ||
                                item.etat === 'A payer') &&
                              item.FactureAvoir.length === 0) ||
                              (item.type == 'Facture d\'avoir' &&
                                item.motif_avoir == 'ristourne' &&
                                checkPermission('FLMFFC')))
                        "
                        class="button-no-style"
                        title="Modifier Facture"
                        @click.prevent.stop="
                          onClickNavigate(item.id, 'Modification')
                        "
                      >
                        Modifier facture
                      </b-button>
                      <b-button
                        v-if="checkPermission('FLTFP')"
                        class="button-no-style"
                        title="Télécharger Facture"
                        @click.prevent.stop="FacuresToDownloadOnePDF(item)"
                      >
                        <div
                          :class="{
                            'd-flex': item.downloadPDF,
                            'justify-content-center': item.downloadPDF
                          }"
                        >
                          Télécharger facture
                          <div
                            v-if="item.downloadPDF"
                            class="loading-download ml-2"
                          >
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </div>
                      </b-button>

                      <b-button
                        v-if="
                          item.type !== 'Facture d\'avoir' &&
                            checkPermission('FLDF') &&
                            item &&
                            item.FactureAvoir &&
                            !item.FactureAvoir.some(
                              e => e.motif_avoir === 'avoir_global'
                            ) 
                        "
                        class="button-no-style"
                        title="Dupliquer Facture"
                        @click.prevent.stop="
                          onClickNavigate(item.id, 'Duplication')
                        "
                      >
                        Dupliquer facture
                      </b-button>
                      <b-button
                        class="button-no-style"
                        title=" Avoir sur Facture"
                        v-if="
                          item.type !== 'Facture d\'avoir' &&
                            item.type !== 'Facture proforma' &&
                            ((checkPermission('FLAVF') &&
                              (item.user_id === idConnecter ||
                                roleConnecter === 'admin')) ||
                              checkPermission('FLCAPT')) &&
                            item.FactureAvoir.length === 0
                        "
                        @click.prevent.stop="openConfirmAvoirModal(item)"
                      >
                        Avoir sur facture
                      </b-button>
                      <b-button
                        class="button-no-style"
                        title="Transformer vers facture"
                        v-if="item.type === 'Facture proforma'"
                        @click.prevent.stop="trasformFacture(item)"
                      >
                        <div
                          :class="{
                            'd-flex': item.transferePro,
                            'justify-content-center': item.transferePro
                          }"
                        >
                          Transformer vers facture
                          <div
                            v-if="item.transferePro"
                            class="loading-download ml-2"
                          >
                            <div class="spinner-border" role="status"></div>
                          </div>
                        </div>
                      </b-button>
                      <br />
                      <b-button
                        v-if="
                          checkPermission('FLPJVC') &&
                            item.type !== 'Facture d\'avoir'
                        "
                        class="button-no-style"
                        title="Modifier Facture"
                        @click.prevent.stop="handleFichiers(item)"
                      >
                        Pièce jointes
                      </b-button>
                      <Historique
                        :awesome="false"
                        :permission="checkPermission('FLHFVC')"
                        :index="item.id"
                        :dataToUse="item.historique"
                        @returnHistorique="returnHistorique"
                        :styleMenuBtn="true"
                      />
                    </b-popover>
                  </td>
                </span>
              </template>
              <template v-if="item.showDetails === true">
                <span
                  v-for="(itemD, index) in item.FactureAvoir"
                  :key="index"
                  class="align-td-item border-botom background-depot"
                >
                  <td class="item-details-style col-check-obligee">
                    {{ itemD.error }}
                    <b-form-checkbox
                      v-model="itemD.checkAvoir"
                      @change="
                        checkAllVerifFactureAvoir(data.item, item, itemD, index)
                      "
                      class="check-th-details ml-2"
                      :value="true"
                      :unchecked-value="false"
                    >
                    </b-form-checkbox>
                  </td>
                  <td class="item-details-style col-plus-obligee"></td>
                  <td class="item-details-style col-plus-obligee"></td>
                  <td
                    v-if="checkPermission('FLACC')"
                    class="item-details-style"
                  >
                    {{ itemD.acheteur }}
                  </td>
                  <td
                    v-if="checkPermission('FLACTD')"
                    class="item-details-style"
                  >
                    {{ itemD.type }}
                  </td>
                  <td
                    v-if="checkPermission('FLACS')"
                    class="item-details-style"
                  >
                    {{ itemD.vendeur }}
                  </td>
                  <td
                    v-if="checkPermission('FLACN')"
                    class="item-details-style"
                  >
                    {{ itemD.num }}
                  </td>
                  <td
                    v-if="checkPermission('FLACDC')"
                    class="item-details-style"
                  >
                    {{ itemD.date_creation }}
                  </td>
                  <td
                    v-if="checkPermission('FLACRAP')"
                    class="item-details-style"
                  >
                    -
                  </td>
                  <td
                    v-if="checkPermission('FLACE')"
                    class="item-details-style"
                  >
                    {{ item.etat == 'Annulée' ? 'Verrouillée' : '-' }}
                  </td>
                  <td
                    v-if="checkPermission('FLACNHT')"
                    class="item-details-style"
                  >
                    {{ itemD.total_net_ht }} €
                  </td>
                  <td
                    v-if="checkPermission('FLACTVA')"
                    class="item-details-style"
                  >
                    {{ itemD.total_tva }} €
                  </td>
                  <td
                    v-if="checkPermission('FLACTTTC')"
                    class="item-details-style"
                  >
                    {{ itemD.total_ttc }} €
                  </td>
                  <td
                    v-if="checkPermission('FLADP')"
                    class="item-details-style"
                  >
                    {{ itemD.date_paiement }}
                  </td>
                  <td
                    v-if="checkPermission('FLARG')"
                    class="item-details-style"
                  >
                    -
                  </td>
                  <td
                    v-if="checkPermission('FLACSC')"
                    class="item-details-style"
                  >
                    -
                  </td>
                  <td
                    v-if="checkPermission('FLACCAT')"
                    class="item-details-style"
                  >
                    {{ itemD && itemD.categorie && itemD.categorie }}
                  </td>
                  <td
                    v-if="checkPermission('FLACCONF')"
                    class="item-details-style"
                  >
                    {{ itemD.confirme === true ? 'OUI' : 'NON' }}
                  </td>
                  <td
                    v-if="checkPermission('FLACRP')"
                    class="item-details-style"
                  >
                    {{ itemD.user }}
                  </td>
                  <td
                    v-if="checkPermission('FLACCP')"
                    class="item-details-style"
                  >
                    -
                  </td>
                  <td class="item-details-style actions-facture-avoir">
                    <div
                      class="
                        block-service-style
                        d-flex
                        justify-content-center
                        m-1
                      "
                    >
                      <button
                        :id="`popover-target-${itemD.id}`"
                        type="button"
                        class="btn btn-secondary bars"
                      >
                        <font-awesome-icon icon="bars" />
                      </button>
                      <b-popover
                        :target="`popover-target-${itemD.id}`"
                        :triggers="computedPopover"
                        placement="bottom"
                        :custom-class="computedStyle"
                      >
                        <b-button
                          class="button-no-style"
                          title="Afficher Facture"
                          v-if="checkPermission('FLAF')"
                          @click.prevent.stop="visualisation(itemD)"
                        >
                          Afficher facture
                        </b-button>
                        <b-button
                          v-if="
                            checkPermission('FLMF') &&
                              itemD.motif_avoir !== 'avoir_global'
                          "
                          class="button-no-style"
                          title="Modifier Facture"
                          @click.prevent.stop="
                            onClickNavigate(data.itemD.id, 'Modification')
                          "
                        >
                          Modifier facture
                        </b-button>
                        <b-button
                          v-if="checkPermission('FLTFP')"
                          @click.prevent.stop="FacuresToDownloadOnePDF(itemD)"
                          class="button-no-style"
                          title="Télécharger Facture"
                        >
                          <div
                            :class="{
                              'd-flex': itemD.downloadPDF,
                              'justify-content-center': itemD.downloadPDF
                            }"
                          >
                            Télécharger facture
                            <div
                              v-if="itemD.downloadPDF"
                              class="loading-download ml-2"
                            >
                              <div class="spinner-border" role="status"></div>
                            </div>
                          </div>
                        </b-button>
                        <Historique
                          :awesome="false"
                          :permission="checkPermission('FLHFAVC')"
                          :index="itemD.id"
                          :dataToUse="itemD.historique"
                          @returnHistorique="returnHistorique"
                          :styleMenuBtn="true"
                        />
                      </b-popover>
                    </div>
                  </td>
                </span>
              </template>
            </span>
          </template>
        </b-table>

        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getFactureLibreTotalRowsTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="ModalPieceJoint"
      id="ModalPieceJoint"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      title="Facture"
      modal-class="cutsom-modal-bootstrap custom-modal-upload-files"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Fichiers jointes</div>
        <div class="iconClose" @click.prevent="hideModal('ModalPieceJoint')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style"
            @submit.prevent="handleSubmitFiles"
          >
            <div class="body-box-files-input">
              <div class="doc-list-file mt-1 box-upload">
                <div class="form-type">
                  <div class="form-groupe">
                    <div>
                      <b-form-file
                        ref="file-type"
                        v-model="files"
                        :required="false"
                        placeholder="Aucun fichier selectionné"
                        drop-placeholder="Drop file here..."
                        multiple
                      >
                      </b-form-file>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="files.length != 0">
              <div class="hader mb-1 mt-1">
                <div class="titleSetting">Nouveau(x) Fichier(s)</div>
              </div>
              <div class="body-box-files">
                <div class="doc-list-file mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon ml-3"
                            />
                            {{ file.name }}
                          </p>
                          <p class="file-name">
                            <b-form-group
                              label="Description"
                              label-for="description"
                              class="input-modal-champ"
                            ></b-form-group>
                            <b-form-textarea
                              id="description"
                              v-model="file.description"
                            ></b-form-textarea>
                          </p>
                          <p class="file-name-trash">
                            <font-awesome-icon
                              icon="trash"
                              class="file-trash-upload-icon"
                              style="float: right"
                              @click.prevent.stop="deleteFile(index)"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <b-row class="ml-3">
                <p class="text-dark">Fichiers Récentes</p>
              </b-row> -->
              </div>
            </div>
            <div
              v-if="fichiers && fichiers.files && fichiers.files.length != 0"
            >
              <div class="hader mb-1 mt-1">
                <div class="titleSetting">Fichier(s) Récent(s)</div>
              </div>
              <div class="body-box-files">
                <div class="doc-list-file mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in fichiers.files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon ml-3"
                            />
                            <a :href="file.link" target="_blank">{{
                              file.name
                            }}</a>
                          </p>
                          <p class="file-name">
                            <b-form-group
                              label="Description"
                              label-for="description"
                              class="input-modal-champ"
                            ></b-form-group>
                            <b-form-textarea
                              id="description"
                              v-model="file.description"
                            ></b-form-textarea>
                          </p>
                          <p class="file-name-trash">
                            <font-awesome-icon
                              icon="trash"
                              class="file-trash-upload-icon"
                              style="float: right"
                              @click.prevent.stop="
                                deleteFilesLibre(file, index)
                              "
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mr-2">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style"
                @click.prevent.stop="hideModal('ModalPieceJoint')"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>

    <b-modal
      ref="afficherFacture"
      id="afficherFacture"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      title="Facture"
      modal-class="cutsom-modal-bootstrap modal-dialog-visualisation"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Facture</div>
        <div class="actionModel d-flex justify-content-center mb-2">
          <b-button
            v-if="checkPermission('FLABPS') && isAvoir === false"
            class="button-valide-style ml-4"
            @click="visualisationPrecedent"
            type="button"
            :disabled="this.indexBegin === 0"
          >
            <span>
              Précédent
              <div class="loading ml-2"></div>
            </span>
          </b-button>
          <b-button
            v-if="checkPermission('FLABPS') && isAvoir === false"
            class="button-valide-style ml-4"
            @click="visualisationAvant"
            type="button"
            :disabled="this.indexBegin === getFacturesLibresTh.length - 1"
          >
            <span>
              Suivant
              <div class="loading ml-2"></div>
            </span>
          </b-button>
          <b-button
            v-if="checkPermission('FLTFP') && isAvoir === false"
            class="button-valide-style ml-4"
            @click="FacuresToDownloadOnePDF(idToDownload)"
            type="button"
            title="Télecharger facture"
          >
            <font-awesome-icon icon="download" />
          </b-button>
          <b-button
            v-if="isAvoir === false && checkPermission('FLDF')"
            class="button-valide-style ml-4"
            @click="onClickNavigate(idToDownload.id, 'duplication')"
            type="button"
            title="Dupliquer facture"
          >
            <font-awesome-icon icon="clone" />
          </b-button>
        </div>
        <div class="iconClose" @click.prevent="hideModal('afficherFacture')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <template>
        <Card>
          <template v-slot:body>
            <div
              class="loader-export-globale-cdg d-flex justify-content-center"
              v-if="displayFactureLoading == true"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loader"
              />
            </div>

            <iframe
              v-else
              height="750"
              width="700"
              :src="pdfToDisplayInIndex"
              scrolling="no"
            ></iframe>
          </template>
        </Card>
      </template>
    </b-modal>
    <b-modal
      ref="validationAvoirModal"
      id="validationAvoirModal"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Confirmation avoir</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('validationAvoirModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form @submit.prevent="CreateGlobalAvoir" class="form-modal-custom-style">
        <div class="mt-4 ml-3">
          <p class="text-center mb-1">
            Êtes-vous sûr de vouloir créer une avoir?
          </p>
        </div>
        <div class=" ml-3 mb-3" v-if="checkPermission('FLDCFA')">
          <b-form-group
            label="Date de création"
            label-for="date-avoir-total"
            class="input-modal-champ mb-0 input-modal-champ-custom-avoir"
          >
            <date-picker
              value-type="format"
              type="date"
              v-model="dateAvoirTotal"
              :clear-button="true"
            ></date-picker
          ></b-form-group>
        </div>
        <div class="actionModel">
          <b-button
            type="submit"
            class="button-valide-style mt-1"
            :disabled="loadingAvoir"
          >
            <span>
              Générer une avoir
              <div v-if="loadingAvoir" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      ref="ModalTransformFacture"
      id="ModalTransformFacture"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      title="Rapport Création Facture"
      modal-class="cutsom-modal-bootstrap  modal-dialog-rapport"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Rapport Création Facture</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('ModalTransformFacture')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card :loaderData="loaderData">
        <template v-slot:body>
          <div v-if="loaderData" class="loading-custom-template-block">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder "
            />
          </div>
          <b-table-simple
            v-else
            class="table-fiche custom-table-style mb-0 justify-content-center displayTable"
            responsive
          >
            <b-tbody>
              <b-tr class="title-ligne">
                <b-th class="newcolor">Société</b-th>
                <b-th class="newcolor">Client</b-th>
                <b-th class="newcolor">Numero Facure</b-th>
                <b-th class="newcolor">Montant</b-th>
                <b-th class="newcolor">Nombre des produits</b-th>
                <b-th class="newcolor">Objet</b-th>
                <b-th class="newcolor">Template</b-th>
              </b-tr>

              <b-tr
                v-for="(item, index) in getListTransformFacture"
                v-bind:key="index"
              >
                <b-td>{{ item.vendeur }}</b-td>
                <b-td>{{ item.client }}</b-td>
                <b-td>{{ item.num }}</b-td>
                <b-td>{{ item.montant_ttc }} € </b-td>
                <b-td>{{ item.number_of_products }} </b-td>
                <b-td>{{ item.objet }} </b-td>
                <b-td>{{ item.template }} </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </Card>
    </b-modal>
    <div
      v-if="
        computedTotal &&
          (computedTotal.ht > 0 ||
            computedTotal.ttc > 0 ||
            computedTotal.someApaye > 0)
      "
      class="style-somme-th-ttc"
    >
      <p class="m-2">
        Total ht:
        {{   computedTotal.ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
        € {{ '   ' }}Total ttc:

        {{
          computedTotal.ttc
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            .replaceAll(',', ' ')
        }}
        € Total à payer :
        {{
          computedTotal.someApaye
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            .replaceAll(',', ' ')
        }}
        €
      </p>
    </div>
  </div>
</template>
<script>
import ExportDynamic from '../ThProject/frais/fraisDynamic/component/exportDynamic.vue';
import moment from 'moment';
import filterComponent from '../ThProject/frais/component/filterComponent.vue';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
// import DatePicker from 'vue2-datepicker';
export default {
  name: 'Facture-libre',
  data() {
    return {
      loadingAvoir: false,
      dateAvoirTotal: null,
      triggersPop: 'hover',
      showPopover: false,
      idToDownload: { id: null },
      roleConnecter: null,
      isAvoir: false,
      vueToDisplay: false,
      libre: 'libre',
      fichiers: null,
      files: [],
      loading: false,
      pdfSource: null,
      pdfToDisplayInIndex: null,
      selected: [],
      checkAllColumn: true,
      filialConnected: null,
      checkAllColumnF: true,
      error: null,
      isLoading: false,
      num: null,
      type: [],
      etat: [],
      vendeur: [],
      produit: [],
      productNamesArray: [],
      indexBegin: null,
      categorie: [],
      acheteur: [],
      displayFactureLoading: false,
      mode_reglement: [],
      date_debut: moment(Date.now())
        .subtract(2, 'months')
        .format('YYYY-MM-DD'),
      date_fin: null,
      objet: null,
      isOpen: false,
      facturToAvoir: null,
      vueOptions: [
        { text: 'Par défaut', value: false },
        { text: 'Client', value: true }
      ],
      rolesToDuplicate: [
        'responsable_controle_de_gestion',
        'admin',
        'responsable_finance'
      ],
      idConnecter: null,
      filialsResponsables: [],
      connectedRole: null,
      column: 'id',
      order: 'DESC',
      checkAll: false,
      dataToUse: [],
      professionnelSocite: [],
      factureAvoirChecked: [],
      etatList: [
        { value: 'Payé', text: 'Payé' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'A payer ', text: 'A payer ' }
      ],
      etatListFilter: [
        { value: null, text: 'Tous' },
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'A payer ', text: 'A payer ' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ],
      indexSelectIsOpen: null,
      indexSelectIsOpenClient: null,
      perPage: 50,
      page: 1,
      nums: null,
      perPageList: [
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      modeReglementList: [
        { value: null, text: 'Tous' },
        { value: 'Virement bancaire', text: 'Virement bancaire' },
        { value: 'Carte bancaire', text: 'Carte bancaire' },
        { value: 'Espèses', text: 'Espèses' },
        {
          value: 'LCR Lettre de change Relevé',
          text: 'LCR Lettre de change Relevé'
        },
        { value: 'Prélèvement', text: 'Prélèvement' },
        { value: 'PayPal', text: 'PayPal' }
      ],
      typeFacture: [
        { value: null, text: 'Tous' },
        { value: 'Facture', text: 'Facture' },
        { value: 'Facture Proforma', text: 'Facture proforma' },
        // { value: "Facture d'acompte", text: "Facture d'acompte" },
        // { value: 'Facture de solde', text: 'Facture de solde' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      options: [
        { text: 'Type Facture', value: 'type' },
        { text: 'Societe', value: 'societe' },
        { text: 'Numéro Facture', value: 'numero' },
        { text: 'Client', value: 'client' },
        { text: 'Montant NET HT', value: 'montant_net_ht' },
        { text: 'Montant TVA', value: 'montant_tva' },
        { text: 'Montant TTC', value: 'montant_ttc' },
        { text: 'Statut', value: 'statut' },
        { text: 'Objet', value: 'objet' },
        { text: 'Iban', value: 'iban' },
        { text: 'Bic', value: 'swift' },
        { text: 'Date paiement', value: 'date_paiement' },
        { text: 'Mode reglement', value: 'mode_reglement' },
        { text: 'Date de création', value: 'date' },
        { text: 'Catégorie', value: 'categorie' }
      ],
      optionProduct: [
        { text: 'Nom', value: 'nom_prod' },
        { text: 'Réfernce', value: 'ref_prod' },
        { text: 'Qantité', value: 'qte_prod' },
        { text: 'Unité ', value: 'unite_prod' },
        { text: 'PU HT', value: 'pu_ht_prod' },
        { text: 'Réduction', value: 'reduction_prod' },
        { text: 'NET HT', value: 'net_ht_prod' },
        { text: 'TVA', value: 'tva_prod' },
        { text: 'TTC', value: 'ttc_prod' },
        { text: 'Description', value: 'description_prod' }
      ],
      loaderData: false
    };
  },
  filters: {
    formateDate: value => {
      return moment(new Date(value)).format('DD-MM-YYYY');
    }
  },
  computed: {
    ...mapGetters([
      'getListProduitForFiltre',
      'checkPermission',
      'getFacturesLibresTh',
      'getFactureLibreLoadingTh',
      'getFactureLibreErroresTh',
      'getFactureLibreTotalRowsTh',
      'getSettingFilialesTh',
      'getUserData',
      'getFiliaeOfResponsable',
      'getAllCategories',
      'getLoadingMailFactureLibre',
      'getErrorMailingFactureLibre',
      'getSuccessSendMailsFactureLibre',
      'getOtherErrorMailFactureLibre',
      'getShowNotifExportFile',
      'TypeReglement',
      'formateDateGetters',
      'getUserData',
      'getListTransformFacture'
    ]),
    computedPopoverTriggers() {
      return this.triggersPop;
    },
    computedStyle() {
      return this.showPopover == true ? 'd-none' : '';
    },
    computedPopover() {
      return this.showPopover == true ? 'click' : 'focus';
    },
    stylePopover() {
      if (this.computedPopoverTriggers == 'click') {
        return 'displayed-none';
      }
      return '';
    },
    computedTotal() {
      if (this.vueToDisplay === false) {
        let someHt = 0;
        let someHTAvoir = 0;
        let someTtc = 0;
        let somTtcAvoir = 0;
        let someApaye = 0;
        for (let i = 0; i < this.computedGetDataToUseTotal.length; i++) {
          // if (this.computedGetDataToUse[i].FactureAvoir.length === 0) {
          someHt = someHt + this.computedGetDataToUseTotal[i].total_ht;
          someTtc = someTtc + this.computedGetDataToUseTotal[i].ttc;
          someApaye = someApaye + this.computedGetDataToUseTotal[i].reste;
          if (this.computedGetDataToUseTotal[i].FactureAvoir.length !== 0) {
            for (
              let j = 0;
              j < this.computedGetDataToUseTotal[i].FactureAvoir.length;
              j++
            ) {
              someHTAvoir =
                someHTAvoir +
                this.computedGetDataToUseTotal[i].FactureAvoir[j].total_ht;
              somTtcAvoir =
                somTtcAvoir +
                parseFloat(
                  this.computedGetDataToUseTotal[i]?.FactureAvoir[
                    j
                  ].total_ttc.replace(' ', '')
                );
            }
          }
          // }
        }
        return {
          ht:
            Math.round(
              (Math.round(someHt * 100) / 100 -
                Math.round(someHTAvoir * 100) / 100) *
                100
            ) / 100,
          ttc:
            Math.round(
              (Math.round(someTtc * 100) / 100 -
                Math.round(somTtcAvoir * 100) / 100) *
                100
            ) / 100,
          someApaye: Math.round(someApaye * 100) / 100
        };
      } else {
        let someHt = 0;
        let someTtc = 0;
        let someApaye = 0;
        let someHTAvoir = 0;
        let somTtcAvoir = 0;
        for (let i = 0; i < this.computedCheckedRows?.length; i++) {
          someHt = someHt + this.computedCheckedRows[i].total_ht;
          someTtc = someTtc + this.computedCheckedRows[i].ttc;
          someApaye = someApaye + this.computedCheckedRows[i].reste;
          if (this.computedCheckedRows[i]?.FactureAvoir?.length !== 0) {
            for (
              let j = 0;
              j < this.computedCheckedRows[i]?.FactureAvoir?.length;
              j++
            ) {
              someHTAvoir =
                someHTAvoir +
                this.computedCheckedRows[i].FactureAvoir[j].total_ht;

              somTtcAvoir =
                somTtcAvoir +
                parseFloat(
                  this.computedCheckedRows[i]?.FactureAvoir[
                    j
                  ]?.total_ttc.replace(' ', '')
                );
            }
          }
          // }
        }
        return {
          ht:
            Math.round(
              (Math.round(someHt * 100) / 100 -
                Math.round(someHTAvoir * 100) / 100) *
                100
            ) / 100,
          ttc:
            Math.round(
              (Math.round(someTtc * 100) / 100 -
                Math.round(somTtcAvoir * 100) / 100) *
                100
            ) / 100,
          someApaye: Math.round(someApaye * 100) / 100
        };
      }
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.vendeur.length > 0 ||
        this.acheteur.length > 0 ||
        this.etat.length > 0 ||
        this.type.length > 0 ||
        this.mode_reglement.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);

      if (this.vueToDisplay == true) {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(element => {
            if (element.factures) {
              element.factures.forEach(facture => {
                if (facture.check == true) {
                  tableReglementCheck.push(facture);
                }
                if (
                  facture.check == true &&
                  facture.reste != '0' &&
                  facture.type != 'Facture proforma' &&
                  (facture.etat == 'A payer' ||
                    facture.etat == 'Payé en partie' ||
                    facture.etat == 'Annulée') &&
                  facture.belongsTo == true
                ) {
                  if (tableReglement.length > 0) {
                    if (filiale_list.includes(tableReglement[0].client_id)) {
                      if (
                        facture.vendeur_id == tableReglement[0].vendeur_id &&
                        filiale_list.includes(facture.client_id) &&
                        facture.client_id == tableReglement[0].client_id
                      ) {
                        tableReglement.push(facture);
                      }
                    } else {
                      if (
                        facture.client_id == tableReglement[0].client_id &&
                        facture.vendeur_id == tableReglement[0].vendeur_id
                      ) {
                        tableReglement.push(facture);
                      }
                    }
                  } else {
                    tableReglement.push(facture);
                  }
                }
              });
            }
          });
        }
      } else {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' ||
                facture.etat == 'Payé en partie' ||
                facture.etat == 'Annulée') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        }
      }
      return tableReglement.length == tableReglementCheck.length &&
        tableReglement.length > 0
        ? true
        : false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);

      if (this.vueToDisplay == true) {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(element => {
            if (element.factures) {
              element.factures.forEach(facture => {
                if (
                  facture.check == true &&
                  facture.reste != '0' &&
                  facture.type != 'Facture proforma' &&
                  (facture.etat == 'A payer' ||
                    facture.etat == 'Payé en partie') &&
                  facture.belongsTo == true
                ) {
                  if (tableReglement.length > 0) {
                    if (filiale_list.includes(tableReglement[0].client_id)) {
                      if (
                        facture.vendeur_id == tableReglement[0].vendeur_id &&
                        filiale_list.includes(facture.client_id) &&
                        facture.client_id == tableReglement[0].client_id
                      ) {
                        tableReglement.push(facture);
                      }
                    } else {
                      if (
                        facture.client_id == tableReglement[0].client_id &&
                        facture.vendeur_id == tableReglement[0].vendeur_id
                      ) {
                        tableReglement.push(facture);
                      }
                    }
                  } else {
                    tableReglement.push(facture);
                  }
                }
              });
            }
          });
        }
      } else {
        if (this.getFacturesLibresTh.length) {
          this.getFacturesLibresTh.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        }
      }

      return tableReglement;
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedCheckedRows() {
      let result1 = [];
      if (this.vueToDisplay === true) {
        this.getFacturesLibresTh?.map(row => {
          row?.factures?.map(fac => {
            if (fac.check == true) {
              result1.push(fac);
            }
          });
        });
      }
      return result1;
    },
    computedFormatedProductsName() {
      let productNames = [];

      this.productNamesArray.forEach(element => {
        productNames.push({ text: element, value: element });
      });
      return productNames;
    },
    computedFildsWithPermission() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          tdClass: 'col-plus-obligee',
          thClass: 'th-plus-obligee',
          views: true,
          for: 'all',
          show: true
        },
        {
          key: 'pices-icon',
          label: '',
          tdClass: 'col-plus-obligee',
          thClass: 'th-plus-obligee',
          views: true,
          for: 'all',
          show: true
        },
        {
          key: 'type',
          label: 'TYPE DOCUMENT',
          isSortable: true,
          show: this.checkPermission('FLACTD')
        },
        {
          key: 'Vendeur',
          label: 'SOCIETE',
          isSortable: true,
          show: this.checkPermission('FLACS')
        },
        {
          key: 'num',
          label: 'NUMERO',
          isSortable: true,
          show: this.checkPermission('FLACN')
        },
        {
          key: 'date_creation',
          label: 'DATE DE CRÉATION',
          isSortable: true,
          show: this.checkPermission('FLACDC')
        },
        {
          key: 'Acheteur',
          label: 'CLIENT',
          isSortable: true,
          show: this.checkPermission('FLACC')
        },
        {
          key: 'reste',
          label: 'Reste à payer',
          show: this.checkPermission('FLACRAP')
        },
        {
          key: 'etat',
          label: 'ETAT',
          isSortable: true,
          show: this.checkPermission('FLACE')
        },
        {
          key: 'total_net_ht',
          label: 'NET HT',
          isSortable: true,
          show: this.checkPermission('FLACNHT')
        },
        {
          key: 'total_tva',
          label: 'TVA',
          isSortable: true,
          show: this.checkPermission('FLACTVA')
        },
        {
          key: 'total_ttc',
          label: 'TOTAL TTC',
          isSortable: true,
          show: this.checkPermission('FLACTTTC')
        },
        {
          key: 'date_paiement',
          label: 'DATE DE PAIEMENT',
          isSortable: true,
          show: this.checkPermission('FLADP')
        },
        {
          key: 'reglement',
          label: 'REGLEMENT',
          isSortable: false,
          show: this.checkPermission('FLARG')
        },
        {
          key: 'statut_client',
          label: 'STATUE CLIENT',
          isSortable: true,
          show: this.checkPermission('FLACSC')
        },
        {
          key: 'categorie',
          label: 'CATEGORIE',
          isSortable: true,
          show: this.checkPermission('FLACCAT')
        },
        {
          key: 'confirme',
          label: 'COMPTABILISE',
          isSortable: true,
          show: this.checkPermission('FLACCONF')
        },
        {
          key: 'total_net_ht',
          label: 'NET HT',
          isSortable: true,
          show: this.checkPermission('FLACNHT')
        },
        {
          key: 'User',
          label: 'CREE PAR',
          isSortable: true,
          show: this.checkPermission('FLACCP')
        },
        {
          key: 'num_proforma',
          label: 'REFERENCE PROFORMA',
          isSortable: true,
          show: this.checkPermission('FLACRP')
        },

        {
          key: 'action',
          label: '',
          tdClass: 'actions',
          thClass: 'actions',
          isSortable: false,
          show: this.checkPermission('FLAA')
        }
      ];
      return fields.filter(item => item.show === true);
    },
    computedFildsVueClientWithPermission() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          show: true
        },
        {
          key: 'details',
          label: '',
          tdClass: 'col-plus-obligee',
          thClass: 'th-plus-obligee ',
          views: true,
          for: 'all',
          show: true
        },
        {
          key: 'pices-icon',
          label: '',
          tdClass: 'col-plus-obligee',
          thClass: 'th-plus-obligee',
          views: true,
          for: 'all',
          show: true
        },
        {
          key: 'Acheteur',
          label: 'CLIENT',
          isSortable: true,
          show: this.checkPermission('FLACC')
        },
        {
          key: 'type',
          label: 'TYPE DOCUMENT',
          isSortable: true,
          show: this.checkPermission('FLACTD')
        },
        {
          key: 'Vendeur',
          label: 'SOCIETE',
          isSortable: true,
          show: this.checkPermission('FLACS')
        },
        {
          key: 'num',
          label: 'NUMERO',
          isSortable: true,
          show: this.checkPermission('FLACN')
        },
        {
          key: 'date_creation',
          label: 'DATE DE CRÉATION',
          isSortable: true,
          show: this.checkPermission('FLACDC')
        },
        {
          key: 'reste',
          label: 'Reste à payer',
          isSortable: true,
          show: this.checkPermission('FLACRAP')
        },
        {
          key: 'etat',
          label: 'ETAT',
          isSortable: true,
          show: this.checkPermission('FLACE')
        },
        {
          key: 'total_net_ht',
          label: 'NET HT',
          isSortable: true,
          show: this.checkPermission('FLACNHT')
        },
        {
          key: 'total_tva',
          label: 'TVA',
          isSortable: true,
          show: this.checkPermission('FLACTVA')
        },
        {
          key: 'total_ttc',
          label: 'TOTAL TTC',
          isSortable: true,
          show: this.checkPermission('FLACTTTC')
        },
        {
          key: 'date_paiement',
          label: 'DATE DE PAIEMENT',
          isSortable: true,
          show: this.checkPermission('FLADP')
        },
        {
          key: 'reglement',
          label: 'REGLEMENT',
          isSortable: false,
          show: this.checkPermission('FLARG')
        },
        {
          key: 'statut_client',
          label: 'STATUE CLIENT',
          isSortable: true,
          show: this.checkPermission('FLACSC')
        },
        {
          key: 'categorie',
          label: 'CATEGORIE',
          isSortable: true,
          show: this.checkPermission('FLACCAT')
        },

        {
          key: 'confirme',
          label: 'COMPTABILISE',
          isSortable: true,
          show: this.checkPermission('FLACCONF')
        },
        {
          key: 'total_net_ht',
          label: 'NET HT',
          isSortable: true,
          show: this.checkPermission('FLACNHT')
        },
        {
          key: 'User',
          label: 'CREE PAR',
          isSortable: true,
          show: this.checkPermission('FLACCP')
        },
        {
          key: 'num_proforma',
          label: 'REFERENCE PROFORMA',
          isSortable: true,
          show: this.checkPermission('FLACRP')
        },

        {
          key: 'action',
          label: '',
          tdClass: 'actions',
          thClass: 'actions',
          isSortable: false,
          show: this.checkPermission('FLAA')
        }
      ];
      return fields.filter(item => item.show === true);
    },
    computedGetDataToUse() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check === true && row.type !== "Facture d'avoir"
      );
      return checkesRows;
    },
    computedGetDataToUseTotal() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check === true
      );
      return checkesRows;
    },
    ComputedFilter() {
      return {
        num: this.num,
        type: this.type,
        etat: this.etat,
        vendeur: this.vendeur,
        categorie: this.categorie,
        acheteur: this.acheteur,
        mode_reglement: this.mode_reglement,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        objet: this.objet,
        col: this.column
      };
    },
    computedCheckRows() {
      let selected = [];
      this?.getFacturesLibresTh?.forEach(element => {
        if (element.check == true) {
          selected.push(element.id);
        }
      });
      return selected;
    },
    computedCheckRowsexportXls() {
      let result1 = [];

      if (this.vueToDisplay === true) {
        this.getFacturesLibresTh?.map(row => {
          row?.factures?.map(fac => {
            if (fac.check == true) {
              result1.push(fac.id);
            }
          });
        });
      }
      return result1;
    },
    computegetSettingFilialesTh() {
      let vendeurNames = [];
      this.getFiliaeOfResponsable.map(item => {
        if (item.name !== null) {
          vendeurNames.push({ value: item.id, text: item.name });
        }
      });
      return vendeurNames;
    },
    computeVendeursNames() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return item.name;
      });
      return vendeurNames;
    },
    computedGetAcheteurs() {
      let acheteurNames = [];
      this.getSettingFilialesTh.map(item => {
        // if (item.type === 'particulier' || item.type === 'professionnel') {
        if (item.name !== null) {
          acheteurNames.push({ value: item.id, text: item.name });
        }
      });
      let jsonObject = acheteurNames.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      uniqueArray.sort((a, b) => a.text.localeCompare(b.text));
      return uniqueArray;
    },
    computeGetcategories() {
      let arrayCategorie = this.getAllCategories.map(item => {
        return { value: item.id, text: item.nom_categorie };
      });
      let jsonObject = arrayCategorie.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      uniqueArray.sort((a, b) => a.text.localeCompare(b.text));
      return uniqueArray;
    },
    computedListProduct() {
      return function(data) {
        if (data && data.produits) {
          let p = '';
          const tableProduit = data.produits.filter(
            item => item.type == 'produit'
          );
          for (let i = 0; i < tableProduit.length; i++) {
            let p1 = i < tableProduit.length - 1 ? ', ' : '';
            p = p + ' ' + tableProduit[i].nom + p1;
          }
          return p;
        }
      };
    },
    computedcheckRolePermission() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_filiale'
      ) {
        return false;
      }
      return true;
    },
    computedWidth() {
      return function(data) {
        if (data && data.etat && data.etat == 'Payé en partie') {
          let montant = (data.montant_payer * 100) / data.total_ttc;
          if (montant > 100) {
            montant = 100;
          }
          return { width: montant + '%' };
        } else {
          return { width: '0%' };
        }
      };
    },
    computedCheck() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    },
    computedChekAvoir() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      if (checkesRows.length > 0) {
        return true;
      }
      return false;
    }
  },
  components: {
    // DatePicker,
    // MailModal,
    filterComponent,
    ExportDynamic,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    Card: () => import('@/views/component/card.vue'),
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    updateReglementModal: () =>
      import('@/views/component/reglement/updateReglementModal.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    InputFileExcelNumDossier: () =>
      import('../ThProject/componentRapportTh/inputFileExcelNumDossier.vue')
  },
  methods: {
    ...mapActions([
      'exportFactureLibre',
      'downloadPDF',
      'displayFact',
      'fetchAllFacturesLibres',
      'getSettingFilialeTh',
      'updatePaymentDate',
      'downloadZIP',
      'downloadOnePDF',
      'fetchAllCategories',
      'getSettingFilialeThNew',
      'confirmedBills',
      'CreateBillAvoirGlobal',
      'exportExcelFactureLibre',
      'getFilialsOfConnectedResponsable',
      'getCategoriesFactureLibre',
      'transformProformatToFacture',
      'sendMailFactureLibre',
      'getAllProductsForfiltre',
      'updateClientStatuPayment',
      'initFactureLibreState',
      'getAllTypesReglement',
      'uploadFileLibre',
      'deleteAttachementLibre',
      'updateFileLibre',
      'fetshDataDetailsFacturs',
      'fecthFactureNumbersInExcelFile'
    ]),
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFiltre();
      }
    },
    returnHistorique(data) {
      this.showPopover = data;
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    async getDataForOneRow(data) {
      data.toggleDetails();
      if (data.detailsShowing == false) {
        await this.fetshDataDetailsFacturs({
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          item: data.item,
          type: this.type.filter(item => item?.value != null),
          etat: this.etat.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          produit: this.produit?.filter(item => item?.value != null),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
      }
    },
    onClickNavigate(id, type) {
      if (type === 'Modification') {
        this.$router.push(`/update-facture/${id}`);
      } else {
        this.$router.push(`/duplicate-facture/${id}`);
      }
    },
    async handleSubmitFiles() {
      this.error = [];
      if (this.files.length != 0 || this.fichiers.files.length != 0) {
        this.loading = true;
        if (this.fichiers?.files?.length != 0) {
          let bodyFormData = new FormData();
          for (let i = 0; i < this.fichiers.files?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file_id]',
              this.fichiers.files[i].id
            );
            bodyFormData.append(
              'files[' + i + '][description]',
              this.fichiers.files[i].description
            );
          }
          const responseUpdate = await this.updateFileLibre({
            bodyFormData: bodyFormData,
            facture: this.fichiers
          });
          if (responseUpdate.success) {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('facture_id', this.fichiers.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const responseUpload = await this.uploadFileLibre({
                bodyFormData: bodyFormData,
                facture_id: this.fichiers.id,
                vue: this.vueToDisplay
              });
              if (responseUpload.success) {
                this.loading = false;
                this.files = [];
                // this.hideModal('ModalPieceJoint');
              }
            } else {
              this.loading = false;
              // this.hideModal('ModalPieceJoint');
            }
          }
        } else {
          if (this.files.length != 0) {
            let bodyFormData = new FormData();
            bodyFormData.append('facture_id', this.fichiers.id);
            for (let i = 0; i < this.files?.length; i++) {
              bodyFormData.append('files[' + i + '][file]', this.files[i]);
              if (this.files[i]['description'] != undefined) {
                bodyFormData.append(
                  'files_descriptions[' + i + ']',
                  this.files[i]['description']
                );
              }
            }
            const responseUploadFile = await this.uploadFileLibre({
              bodyFormData: bodyFormData,
              facture_id: this.fichiers.id,
              vue: false
            });
            if (responseUploadFile.success) {
              this.loading = false;
              // this.hideModal('ModalPieceJoint');
            }
          }
        }
      } else {
        this.error = 'Importer au moins un fichier';
      }
    },
    resetModal() {
      this.fichiers = {};
      this.files = [];
      this.dateAvoirTotal = null;
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    handleFichiers(item) {
      this.fichiers = item;
      this.$refs['ModalPieceJoint'].show();
    },
    async deleteFilesLibre(item, index) {
      const response = await this.deleteAttachementLibre(item.id);
      if (response) {
        this.fichiers.files.splice(index, 1);
      }
    },
    rowClass(item, type) {
      return 'ligneNormale';
    },
    checkAllVerifFactureAvoir(data, item, itemD, index) {
      if (itemD.checkAvoir == false && data.checkClient == true) {
        data.checkClient = false;
      }
      if (itemD.checkAvoir == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (itemD.checkAvoir == false && item.checkAll == true) {
        item.checkAll = false;
      }
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.checkClient == true) {
        data.check = false;
      }
      if (data.checkClient == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.FactureAvoir.map(itemP => {
        itemP.checkAvoir = item.check;
      });
    },
    async visualisationAvant() {
      this.displayFactureLoading = true;
      let i = ++this.indexBegin;
      let factureTodisplay = this.getFacturesLibresTh[i];
      const response = await this.displayFact(factureTodisplay?.id);
      if (response.success === true) {
        this.pdfToDisplayInIndex = response.response;
        this.displayFactureLoading = false;
      } else {
        this.displayFactureLoading = false;
      }
    },
    async visualisationPrecedent() {
      this.displayFactureLoading = true;
      let i = --this.indexBegin;
      let factureTodisplay = this.getFacturesLibresTh[i];
      const response = await this.displayFact(factureTodisplay?.id);
      if (response.success === true) {
        this.pdfToDisplayInIndex = response.response;
        this.displayFactureLoading = false;
      } else {
        this.displayFactureLoading = false;
      }
    },
    initModal() {
      this.selected = [
        'type',
        'numero',
        'date',
        'client',
        'statut',
        'montant_net_ht',
        'montant_ttc',
        'objet',
        'montant_tva',
        'societe',
        'iban',
        'swift',
        'date_paiement',
        'mode_reglement',
        'nom_prod',
        'ref_prod',
        'qte_prod',
        'unite_prod',
        'pu_ht_prod',
        'reduction_prod',
        'net_ht_prod',
        'tva_prod',
        'ttc_prod',
        'description_prod',
        'categorie'
      ];
      this.checkAllColumn = true;
      this.checkAllColumnF = true;
    },
    openConfirmAvoirModal(data) {
      this.facturToAvoir = data;
      this.$refs['validationAvoirModal'].show();
    },

    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      if (ref == 'ModalTransformFacture') {
        this.isLoading = true;
        this.fetchAllFacturesLibres({
          groupedBySociete: this.vueToDisplay,
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type?.filter(item => item?.value != null),
          etat: this.etat?.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie?.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
        setTimeout(() => {
          this.isLoading = false;
        }, 800);
      }
    },
    disabledStartDateFilter(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDateFiltre(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    changeAllCheckColumns() {
      if (this.checkAllColumnF == true && this.checkAllColumn === true) {
        this.selected = [
          'type',
          'numero',
          'date',
          'client',
          'statut',
          'montant_net_ht',
          'montant_ttc',
          'objet',
          'montant_tva',
          'societe',
          'iban',
          'swift',
          'date_paiement',
          'mode_reglement',
          'nom_prod',
          'ref_prod',
          'qte_prod',
          'unite_prod',
          'pu_ht_prod',
          'reduction_prod',
          'net_ht_prod',
          'tva_prod',
          'ttc_prod',
          'description_prod',
          'categorie'
        ];
      } else if (
        this.checkAllColumnF == true &&
        this.checkAllColumn === false
      ) {
        this.selected = [
          'nom_prod',
          'ref_prod',
          'qte_prod',
          'unite_prod',
          'pu_ht_prod',
          'reduction_prod',
          'net_ht_prod',
          'tva_prod',
          'ttc_prod',
          'description_prod'
        ];
      } else if (
        this.checkAllColumnF == false &&
        this.checkAllColumn === true
      ) {
        this.selected = [
          'type',
          'numero',
          'date',
          'client',
          'statut',
          'montant_net_ht',
          'montant_ttc',
          'objet',
          'montant_tva',
          'societe',
          'iban',
          'swift',
          'date_paiement',
          'mode_reglement',
          'categorie'
        ];
      } else {
        this.selected = [];
      }
    },
    async handleSendMail(selectedAcheteurVendeur) {
      this.$bvModal.hide('mailModal');
      var bodyFormData1 = new FormData();
      for (let i = 0; i < this.computedGetDataToUse.length; i++) {
        bodyFormData1.append(
          'ids[' + [i] + ']',
          this.computedGetDataToUse[i].id
        );
      }
      bodyFormData1.append('parameter', 'Acheteur');
      var bodyFormData2 = new FormData();
      for (let i = 0; i < this.computedGetDataToUse.length; i++) {
        bodyFormData2.append(
          'ids[' + [i] + ']',
          this.computedGetDataToUse[i].id
        );
      }
      bodyFormData2.append('parameter', 'Vendeur');
      if (selectedAcheteurVendeur.includes('acheteur')) {
        const response = await this.sendMailFactureLibre(bodyFormData1);
        if (response.succes) {
          this?.getErrorMailingFactureLibre?.map(element => {
            if (element.societe.length > 0) {
              this.flashMessage.show({
                status: 'error',
                title: element.message,
                clickable: true,
                time: 0,
                message: element.societe
              });
            }
          });
          if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
            this.flashMessage.show({
              status: 'success',
              title: this.getSuccessSendMailsFactureLibre?.message,
              clickable: true,
              time: 0,
              message: this.getSuccessSendMailsFactureLibre?.societe
            });
          }
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'ERROR',
            clickable: true,
            time: 0,
            message: this.getOtherErrorMailFactureLibre
          });
        }
      }
      if (selectedAcheteurVendeur.includes('vendeur')) {
        const response2 = await this.sendMailFactureLibre(bodyFormData2);
        if (response2.succes) {
          this?.getErrorMailingFactureLibre?.map(element => {
            if (element.societe.length > 0) {
              this.flashMessage.show({
                status: 'error',
                title: element.message,
                clickable: true,
                time: 0,
                message: element.societe
              });
            }
          });
          if (this.getSuccessSendMailsFactureLibre?.societe?.length > 0) {
            this.flashMessage.show({
              status: 'success',
              title: this.getSuccessSendMailsFactureLibre?.message,
              clickable: true,
              time: 0,
              message: this.getSuccessSendMailsFactureLibre?.societe
            });
          }
        } else {
          this.flashMessage.show({
            status: 'error',
            title: 'ERROR',
            clickable: true,
            time: 0,
            message: this.getOtherErrorMailFactureLibre
          });
        }
      }
    },
    onChangeVue() {
      // localStorage.setItem('display-vue', this.vueToDisplay);
      this.initFactureLibreState();
      this.refrech();
    },
    async refrech() {
      this.isLoading = true;
      this.column = 'id';
      this.ordre = 'DESC';
      await this.fetchAllFacturesLibres({
        groupedBySociete: this.vueToDisplay,
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement.filter(item => item?.value != null),
        produit: this.produit.filter(item => item?.value != null),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie?.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        objet: this.objet,
        column: 'id',
        order: 'DESC',
        famille: 'libre'
      });
      this.isLoading = false;
    },
    async handleExportFile() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      var bodyFormData = new FormData();
      for (let i = 0; i < this.selected.length; i++) {
        bodyFormData.append('columns[' + [i] + ']', this.selected[i]);
      }
      let ids = [];

      for (let i = 0; i < checkesRows.length; i++) {
        if (checkesRows[i].type !== "Facture d'avoir") {
          ids.push(checkesRows[i].id);
          bodyFormData.append('ids[' + [i] + ']', checkesRows[i].id);
        }
      }
      if (this.mode_reglement) {
        // bodyFormData.append('mode_reglement', this.mode_reglement);
        for (let i = 0; i < this.mode_reglement.length; i++)
          if (this.mode_reglement[i].value != null) {
            bodyFormData.append(
              'mode_reglement[' + [i] + ']',
              this.mode_reglement[i].value
            );
          }
      }
      if (this.etat) {
        // bodyFormData.append('etat', this.etat);
        for (let i = 0; i < this.etat.length; i++)
          if (this.etat[i].value != null) {
            bodyFormData.append('etat[' + [i] + ']', this.etat[i].value);
          }
      }
      if (this.type) {
        for (let i = 0; i < this.type.length; i++)
          if (this.type[i].value != null) {
            bodyFormData.append('type[' + [i] + ']', this.type[i].value);
          }
      }
      if (this.num) {
        bodyFormData.append('num', this.num);
      }
      if (this.vendeur) {
        for (let i = 0; i < this.vendeur.length; i++)
          if (this.vendeur[i].value != null) {
            bodyFormData.append(
              'vendeur_id[' + [i] + ']',
              this.vendeur[i].value
            );
          }
      }
      if (this.categorie) {
        for (let i = 0; i < this.categorie.length; i++)
          if (this.categorie[i].value != null) {
            bodyFormData.append(
              'categorie_id[' + [i] + ']',
              this.categorie[i].value
            );
          }
      }
      if (this.acheteur) {
        for (let i = 0; i < this.acheteur.length; i++)
          if (this.acheteur[i].value != null) {
            bodyFormData.append(
              'acheteur_id[' + [i] + ']',
              this.acheteur[i].value
            );
          }
      }
      if (this.objet) {
        bodyFormData.append('objet', this.objet);
      }
      if (this.date_debut) {
        bodyFormData.append('date_debut', this.date_debut);
      }
      if (this.date_fin) {
        bodyFormData.append('date_fin', this.date_fin);
      }
      if (this.column) {
        bodyFormData.append('column', this.column);
      }
      if (this.order) {
        bodyFormData.append('order', this.order);
      }
      bodyFormData.append('famille', 'libre');

      if (this.selected.length === 0) {
        this.error = 'SVP séléctionner au moin une colonne';
        return;
      }
      //  else if (ids.length === 0) {
      //   this.error = 'SVP séléctionner au moin un document de type Facture';
      //   return;
      // }
      const response = await this.exportExcelFactureLibre(bodyFormData);
      if (response) {
        this.$refs['refExportXlsModal'].hide();
        this.error = null;
      }
    },
    setVisibilityEtatList(data, index) {
      data.openSelect = !data.openSelect;
      if (data.openSelect === true) {
        this.indexSelectIsOpen = { index: index, item: data };
      } else {
        this.indexSelectIsOpen = null;
      }
    },
    setVisibilityEtatClientList(data, index) {
      data.openSelectClient = !data.openSelectClient;
      if (data.openSelectClient === true) {
        this.indexSelectIsOpenClient = { index: index, item: data };
      } else {
        this.indexSelectIsOpenClient = null;
      }
    },
    closeSelectEtat(event) {
      if (
        this.indexSelectIsOpen != null &&
        this.indexSelectIsOpen.item &&
        this.indexSelectIsOpen.item.openSelect == true
      ) {
        let tab = event.path.filter(
          item =>
            item.nodeName == 'DIV' &&
            item.getAttribute('id') ==
              'component' + this.indexSelectIsOpen.index
        );
        if (event.path && tab.length == 0) {
          this.getFacturesLibresTh[
            this.indexSelectIsOpen.index
          ].openSelect = false;
          this.indexSelectIsOpen = null;
        }
      }
    },
    closeSelectEtatClient(event) {
      if (
        this.indexSelectIsOpenClient != null &&
        this.indexSelectIsOpenClient.item &&
        this.indexSelectIsOpenClient.item.openSelectClient == true
      ) {
        let tab = event.path.filter(
          item =>
            item.nodeName == 'DIV' &&
            item.getAttribute('id') ==
              'component' + this.indexSelectIsOpenClient.index
        );
        if (event.path && tab.length == 0) {
          this.getFacturesLibresTh[
            this.indexSelectIsOpenClient.index
          ].openSelect = false;
          this.indexSelectIsOpenClient = null;
        }
      }
    },
    async handleFiltre() {
      this.isLoading = true;
      await this.fetchAllFacturesLibres({
        groupedBySociete: this.vueToDisplay,
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type.filter(item => item?.value != null),
        etat: this.etat.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement?.filter(
          item => item?.value != null
        ),
        produit: this.produit?.filter(item => item?.value != null),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre',
        nums: this.nums
      });
      this.isLoading = false;
    },
    changeSearchValue(event) {
      this.num = event;
      this.handleFiltre();
    },
    async listOrder(col, ord) {
      this.isLoading = true;
      this.column = col;
      this.order = ord;
      await this.fetchAllFacturesLibres({
        groupedBySociete: this.vueToDisplay,
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type?.filter(item => item?.value != null),
        etat: this.etat?.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement?.filter(
          item => item?.value != null
        ),
        produit: this.produit.filter(item => item?.value != null),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie.filter(item => item?.value != null),
        acheteur_id: this.acheteur.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        objet: this.objet,
        column: col,
        order: ord,
        famille: 'libre'
      });
      this.isLoading = false;
    },
    async changePerPage() {
      this.isLoading = true;
      this.page = 1;
      await this.fetchAllFacturesLibres({
        groupedBySociete: this.vueToDisplay,
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type.filter(item => item?.value != null),
        etat: this.etat.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement.filter(item => item?.value != null),
        produit: this.produit.filter(item => item?.value != null),
        vendeur: this.vendeur.filter(item => item.value != null),
        categorie_id: this.categorie.filter(item => item?.value != null),
        acheteur_id: this.acheteur.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre'
      });
      this.isLoading = false;
      // this.setLocalStorageFactureLibre();
    },
    async visualisation(item) {
      if (item.type === "Facture d'avoir") {
        this.isAvoir = true;
      } else {
        this.isAvoir = false;
      }
      let index = this.getFacturesLibresTh.findIndex(
        element => element.id === item.id
      );
      this.idToDownload.id = item.id;
      this.indexBegin = index;
      const response = await this.displayFact(item.id);
      if (response.success === true) {
        // this.loading = false;
        this.pdfToDisplayInIndex = response.response;
      } else {
        // this.error = response.error;
        // this.loading = false;
      }
      this.$refs['afficherFacture'].show();
    },
    async pagination(paginate) {
      this.isLoading = true;
      this.page = paginate;
      await this.fetchAllFacturesLibres({
        groupedBySociete: this.vueToDisplay,
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type.filter(item => item?.value != null),
        etat: this.etat.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement.filter(item => item?.value != null),
        produit: this.produit.filter(item => item?.value != null),
        vendeur: this.vendeur.filter(item => item?.value != null),
        categorie_id: this.categorie.filter(item => item?.value != null),
        acheteur_id: this.acheteur.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre'
      });
      this.isLoading = false;
      // this.setLocalStorageFactureLibre();
    },
    async filterSearch() {
      this.isLoading = true;
      await this.fetchAllFacturesLibres({
        groupedBySociete: this.vueToDisplay,
        per_page: this.perPage,
        page: this.page,
        num: this.num,
        type: this.type.filter(item => item?.value != null),
        etat: this.etat.filter(item => item?.value != null),
        mode_reglement: this.mode_reglement?.filter(
          item => item?.value != null
        ),
        produit: this.produit?.filter(item => item?.value != null),
        vendeur: this.vendeur?.filter(item => item?.value != null),
        categorie_id: this.categorie.filter(item => item?.value != null),
        acheteur_id: this.acheteur?.filter(item => item?.value != null),
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        column: this.column,
        order: this.order,
        objet: this.objet,
        famille: 'libre'
      });
      this.isLoading = false;
      // this.setLocalStorageFactureLibre();
    },
    checkAllFunction() {
      this?.getFacturesLibresTh?.map(item => (item.check = this.checkAll));
    },
    checkAllFunctionClienVue() {
      this?.getFacturesLibresTh?.map(item => {
        item.checkClient = this.checkAll;
        item.factures.map(itemD => {
          itemD.check = this.checkAll;
          itemD.FactureAvoir.map(itemP => {
            itemP.checkAvoir = this.checkAll;
          });
        });
      });
    },
    checkAllVerif(item) {
      if (item.checkClient == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.factures.map(itemD => {
        itemD.check = item.checkClient;
        itemD.FactureAvoir.map(itemP => {
          itemP.checkAvoir = item.check;
        });
      });
    },
    checkOneFunction(data) {
      data.check == !data.check;
    },
    checkOneAvoirFunction(data) {
      if (data.checkAvoir === true) {
        this.factureAvoirChecked.push(data.id);
      } else {
        let index = this.factureAvoirChecked.findIndex(
          element => element.id === data.id
        );
        this.factureAvoirChecked.splice(index, 1);
      }
    },
    async CreateGlobalAvoir() {
      // this.$refs['validationAvoirModal'].hide();
      this.loadingAvoir = true;
      let avoir = { ...this.facturToAvoir };
      avoir.date = this.dateAvoirTotal;
      const response = await this.CreateBillAvoirGlobal(avoir);
      if (response.success) {
        this.loadingAvoir = false;
        this.$refs['validationAvoirModal'].hide();
        this.flashMessage.show({
          status: 'success',
          title: '',
          clickable: true,
          time: 0,
          message: 'Un avoir sur ' + this.facturToAvoir.num + ' a été ajouté'
        });
        await this.fetchAllFacturesLibres({
          groupedBySociete: this.vueToDisplay,
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type?.filter(item => item?.value != null),
          etat: this.etat?.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          produit: this.produit.filter(item => item?.value != null),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie?.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
      } else {
        this.loadingAvoir = false;
        this.$refs['validationAvoirModal'].hide();
        this.flashMessage.show({
          status: 'error',
          title: '',
          clickable: true,
          time: 0,
          message: response.response
        });
      }
      this.dateAvoirTotal = null;
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    async updateEtat(data, result) {
      data.etat = result.value;
      data.openSelect = false;
      await this.updatePaymentDate(data);
    },
    async updateEtatPaimentClient(data, result) {
      data.statut_client = result.value;
      data.openSelectClient = false;
      await this.updateClientStatuPayment(data);
    },
    async FacuresToDownloadPDF() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      await this.downloadPDF(bodyFormData);
    },
    async FacuresToDownloadOnePDF(idPdf) {
      idPdf.downloadPDF = true;
      let ids = [];
      ids.push({
        id: idPdf.id
      });
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', ids[0]['id']);
      await this.downloadPDF({ id: ids[0].id });
      idPdf.downloadPDF = false;
    },

    async FacuresToDownloadZIP() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      await this.downloadZIP(bodyFormData);
    },
    async FacuresToConfirme() {
      let checkesRows = this?.getFacturesLibresTh?.filter(
        row => row.check == true
      );
      let ids = [];
      let bodyFormData = new FormData();
      for (let i = 0; i < checkesRows.length; i++) {
        ids.push(checkesRows[i].id);
      }
      for (let i = 0; i < this.factureAvoirChecked.length; i++) {
        ids.push(this.factureAvoirChecked[i]);
      }
      for (let i = 0; i < ids.length; i++) {
        bodyFormData.append('ids[' + i + ']', ids[i]);
      }
      const response = await this.confirmedBills(bodyFormData);
      if (response === true) {
        this.isLoading = true;
        await this.fetchAllFacturesLibres({
          groupedBySociete: this.vueToDisplay,
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          type: this.type?.filter(item => item?.value != null),
          etat: this.etat?.filter(item => item?.value != null),
          mode_reglement: this.mode_reglement?.filter(
            item => item?.value != null
          ),
          vendeur: this.vendeur?.filter(item => item?.value != null),
          categorie_id: this.categorie?.filter(item => item?.value != null),
          acheteur_id: this.acheteur?.filter(item => item?.value != null),
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          column: this.column,
          order: this.order,
          objet: this.objet,
          famille: 'libre'
        });
        this.isLoading = false;
      }
    },
    async trasformFacture(item) {
      item.transferePro = true;
      this.loaderData = true;
      this.$refs['ModalTransformFacture'].show();
      let bodyFormData = new FormData();
      bodyFormData.append('ids[' + 0 + ']', item.id);

      const response = await this.transformProformatToFacture(bodyFormData);
      if (response) {
        item.transferePro = false;
      } else {
        item.transferePro = false;
      }
      this.loaderData = false;
    },
    checkRole() {
      if (
        this.getUserData &&
        this.getUserData.role &&
        this.getUserData.role == 'responsable_filiale'
      ) {
        let item = this.getSettingFilialesTh.filter(
          element => element.id === this.getUserData.filiale_id
        )[0];
        this.vendeur = item?.name;
      }
    },
    selectAllType() {
      if (this.type.length && this.type[this.type.length - 1]?.value == null) {
        this.type = [{ value: null, text: 'Tous' }];
      } else {
        if (this.type.some(data => data?.value == null)) {
          this.type = this.type.filter(item => item?.value != null);
        }
      }
      this.handleFiltre();
    },

    selectAllEtat() {
      if (this.etat.length && this.etat[this.etat.length - 1]?.value == null) {
        this.etat = [{ value: null, text: 'Tous' }];
      } else {
        if (this.etat.some(data => data?.value == null)) {
          this.etat = this.etat.filter(item => item?.value != null);
        }
      }
      this.handleFiltre();
    },

    selectAllMode() {
      if (
        this.mode_reglement.length &&
        this.mode_reglement[this.mode_reglement.length - 1]?.value == null
      ) {
        this.mode_reglement = [{ value: null, text: 'Tous' }];
      } else {
        if (this.mode_reglement.some(data => data?.value == null)) {
          this.mode_reglement = this.mode_reglement.filter(
            item => item?.value != null
          );
        }
      }
      this.handleFiltre();
    },

    selectAllVendeur() {
      if (
        this.vendeur.length &&
        this.vendeur[this.vendeur.length - 1]?.value == null
      ) {
        this.vendeur = [{ value: null, text: 'Tous' }];
      } else {
        if (this.vendeur.some(data => data?.value == null)) {
          this.vendeur = this.vendeur.filter(item => item?.value != null);
        }
      }
      this.handleFiltre();
    },

    selectAllAcheteur() {
      if (
        this.acheteur.length &&
        this.acheteur[this.acheteur.length - 1]?.value == null
      ) {
        this.acheteur = [{ value: null, text: 'Tous' }];
      } else {
        if (this.acheteur.some(data => data?.value == null)) {
          this.acheteur = this.acheteur.filter(item => item?.value != null);
        }
      }
      this.handleFiltre();
    },

    selectAllCategory() {
      if (
        this.categorie.length &&
        this.categorie[this.categorie.length - 1]?.value == null
      ) {
        this.categorie = [{ value: null, text: 'Tous' }];
      } else {
        if (this.categorie.some(data => data?.value == null)) {
          this.categorie = this.categorie.filter(item => item?.value != null);
        }
      }
      this.handleFiltre();
    }
  },

  async mounted() {
    this.roleConnecter = JSON.parse(localStorage.getItem('User-Details'))?.role;
    this.idConnecter = JSON.parse(localStorage.getItem('User-Details'))?.id;
    this.filialConnected = JSON.parse(
      localStorage.getItem('User-Details')
    ).filiale_name;
    this.connectedRole = JSON.parse(localStorage.getItem('User-Details'))?.role;
    this.isLoading = true;
    await this.getSettingFilialeTh();
    await this.getFilialsOfConnectedResponsable();
    await this.getCategoriesFactureLibre();
    await this.fetchAllFacturesLibres({
      groupedBySociete: localStorage.getItem('display-vue')
        ? localStorage.getItem('display-vue')
        : this.vueToDisplay,
      per_page: this.perPage,
      page: this.page,
      num: this.num,
      type: [],
      etat: [],
      mode_reglement: [],
      vendeur: [],
      categorie_id: [],
      acheteur_id: [],
      date_debut: this.date_debut,
      date_fin: this.date_fin,
      column: this.column,
      order: this.order,
      objet: this.objet,
      famille: 'libre'
    });
    this.isLoading = false;
    await this.getAllTypesReglement();
    await this.getCategoriesFactureLibre();
    await this.getAllProductsForfiltre();
    this.productNamesArray = Object.values(this.getListProduitForFiltre);
    this.checkRole();

    // await this.getSettingFilialeTh();
    this.professionnelSocite = this.getSettingFilialesTh.filter(
      element => element.type === 'professionnel'
    );
    // await this.fetchAllCategories();
    JSON.parse(localStorage.getItem('User-Details'))?.filiales.map(item =>
      this.filialsResponsables.push(item.id)
    );
  },
  directives: {
    ClickOutside
  }
};
</script>
<style lang="scss" scoped>
#ModalTransformFacture {
  .loading-custom-template-block {
    padding-top: 0%;
  }
}
.style-somme-th-ttc {
  font-family: 'Montserrat', sans-serif;
  background-color: #d0e4f5;
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: auto;
  height: 35px;
  border-radius: 30px;
  z-index: 120;
  box-shadow: rgb(0 0 0 / 18%) 0px 4px 12px;
  text-shadow: 0 0 black;
}
.style-btn-add {
  height: 27px;
  font-size: 12px;
  margin-bottom: 0px !important;
  padding: 3px 9px 3px 9px;
}
.confirm-button-style {
  height: 30px;
  width: 30px;
  // margin: auto;
  background-color: #9799d6;
  background-color: green;
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 20px;
}
.confirm-button-style:hover {
  background-color: #4d4bac;
  background-color: green;
}
.button-no-style {
  background-color: #fff;
  border: none;
  color: #000;
  padding: 0;
  width: 100%;
  border-radius: 10px;
  &:hover {
    background-color: #9799d6;
    color: #fff;
  }
}
.body-box-files {
  display: flex;
  background-color: #f5f4fa;
  height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.body-box-files-input {
  display: flex;
  background-color: #f5f4fa;
  // height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
* {
  font-family: 'Montserrat', sans-serif;
}
.body-box-rapport {
  height: calc(100vh - 68px);
  // .tabs-body-style {
  //   width: 220px;
  //   .tab-item-style {
  //     display: inline;
  //     .title-tab-item {
  //       font-family: 'Montserrat', sans-serif;
  //       font-size: 15px;
  //       font-weight: 500;
  //       color: #5d5d5d;
  //     }
  //     .ligne {
  //       width: 100%;
  //       margin: auto;
  //       margin-top: 7px;
  //       margin-bottom: 7px;
  //     }
  //   }
  // }
  .display-tabs {
    display: none;
  }
  .table-rapport-style {
    .table {
      height: calc(100% - 50px);
    }
    .b-table-sticky-header {
      max-height: calc(100vh - 175px) !important;
      height: calc(100vh - 175px);
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  .width-table-rapport {
    width: 100%;
  }
}
#filiale-box-body::-webkit-scrollbar,
#filiale-body::-webkit-scrollbar {
  width: 7px;
}
#filiale-box-body::-webkit-scrollbar-track,
#filiale-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
#filiale-box-body::-webkit-scrollbar-thumb,
#filiale-body::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contenu-tab-users {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
}
.btn-add-tva-unite {
  height: 17px;
  width: 17px;
  background-color: #08a742;
  border: none;
  border-radius: 50%;
}
.btn-add-tva-unite:hover {
  background-color: #087530;
}
.icon-plus-tva-unite {
  color: #fff;
  height: 11px;
  width: 11px;
  margin-bottom: 3px;
}
.color_picker_width {
  width: 25px;
}
.padding-input-number {
  padding: 5px;
}
.td-width {
  width: 150px;
}
</style>
<style lang="scss" scoped>
* {
  font-family: 'Montserrat', sans-serif;
}
// .custom-table-style tbody {
//   text-align: center !important;
//   color: #656565 !important;
//   background-color: #ededed !important;
// }
.style-niveau-trois {
  display: flex;
  justify-content: left;
  background: #bebfe3;
}
.border-botom {
  border-bottom: 1px solid #f6f5fb;
}
.body-box-rapport {
  height: calc(100vh - 68px);
  // .tabs-body-style {
  //   width: 0px;
  // }
  .table-rapport-style {
    width: 100% !important;
    .b-table-sticky-header {
      max-height: calc(100vh - 185px) !important;
      height: calc(100vh - 185px);
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .header-component-facture-libre {
      margin: 5px 0px;
      justify-content: space-between;
    }
  }
}

.content-tab-facture-libre {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
}
.block-service-style {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.fix-margin-td-avoir {
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-right-width: 0px;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7978aa;
  border-radius: 7px;
}
.couleur-facture-global {
  background-color: #f0f1ff;
}
.style-filter-div {
  overflow-x: auto;
  height: calc(100vh - 227px);
}
.style-block--filter {
  height: 100%;
}
.column-size {
  width: 140px;
}
.style-arrow-not-selected {
  width: 14px;
  height: 14px;
  color: #aaacb0;
  // margin-top: 3px;
  margin-left: 1px;
  margin-right: 1px;
}
.style-arrow-selected {
  width: 14px;
  height: 14px;
  color: #4d4bac;
  margin-left: 1px;
  margin-right: 1px;
}
.underline-selected-colomn {
  text-decoration: underline;
}
</style>
<style lang="scss">
.doc-list-file {
  width: 100%;
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;

          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
.form-type {
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 56%;
  margin: auto;

  .form-groupe {
    .file-header-titles {
      align-items: center;
      padding: 4px 20px;
      display: flex;

      .file-name-title,
      .file-category-title {
        text-align: center;
        color: #495057;
        font-weight: 600;
        border-radius: 6px;
        // background-color: #8d8cb7;
        padding: 3px;
      }

      .file-name-title {
        width: 80%;
        margin-right: 10px;
      }

      .file-category-title {
        width: 40%;
      }
    }

    .files-to-upload {
      display: flex;
      align-items: center;
      padding: 2px 20px;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }
      .file-name-trash {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 20%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }

      .file-category {
        width: 40%;
        font-size: 13px;
        border-radius: 17px;
      }
    }

    .actionFile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;

      .button-cancel-style {
        background-color: #49505782;
        color: white;
        border: none;
      }

      .chargement {
        margin-left: 5px;

        .spinner-border {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
  }
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}

.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}
#upload-file-component {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  position: relative;
}

.table-appel-paiement-obligee-th-vue-cilent {
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    color: #fff;
    vertical-align: middle;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb !important;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd9;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #b0afd9 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;

      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
  .actions {
    position: sticky;
    right: -14px !important;
    background-color: #ededed;
  }
  .actions-facture {
    position: sticky;
    right: -14px !important;
    background-color: #e0e0eb;
  }
  .actions-facture-avoir {
    position: sticky;
    right: -14px !important;
    background-color: #b0afd9;
  }
}

.button-13 {
  background-color: #f0f1f2;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
  align-items: center;
  justify-content: center;
  &:focus {
    border-color: #008296;
    box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
    outline: 0;
  }
}
.custom-component-etat-facture-libre {
  display: block;
  position: relative;
  .b-form-select-custom-etat {
    height: 18px;
    border-radius: 100px;
    border-color: #70707038;
    width: 75%;
    margin: auto;
    padding: 0px;
    -moz-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 1px 5px -2px rgb(0 0 0 / 75%);
    min-width: 100px;
    font-size: 89%;
    position: relative;
    cursor: pointer;
  }
  &.open {
    .dropdown-menu {
      display: block;
      min-width: 9rem;
    }
    li {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.428571429;
      color: #333333;
      white-space: nowrap;
      font-size: 10px;
      &:hover {
        background-color: #4d4bac;
        color: white;
      }
    }
  }
  #content {
    position: relative;
    // z-index: 1;
    text-align: center;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }

  #background {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 1;
    border-radius: 100px;
    box-shadow: 2px 1px 5px -2px rgb(0 0 0 / 75%);
  }
  .icon-class-caret-down {
    margin-left: 3px;
    height: 1em !important;
  }
}
.table-factures-libres-style {
  max-height: calc(100vh - 247px) !important;
  height: calc(100vh - 247px) !important;
  margin-bottom: 0px;
  max-width: 100%;
}
.header-component-facture-libre {
  .btn-secondary {
    background-color: #4d4bac;
    border-color: #4d4bac;
    font-size: 10px;
  }
}
.table-rapport-style .customTableFactureLibreVueDefault {
  .item-details-style {
    margin: 2px 0px;
  }
  td,
  th {
    font-size: 8.5px;
    padding: 2px 2px !important;
    min-width: 100px;
    width: 100px;
  }
  .width-service {
    min-width: 40px;
    width: 40px;
  }
}
.three-points-concats {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
}
.white-color-custom-select {
  background-color: #fff;
  color: #000;
}
.red-color-custom-select {
  background: linear-gradient(
    to bottom,
    #ff6d03 0%,
    #ff6d03 0%,
    #ff6d03 51%,
    #ff6d03 71%,
    #ff6d03 100%
  );
  color: #fff;
}
.green-color-custom-select {
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
  color: #fff;
}
.blue-color-custom-select {
  background: linear-gradient(#54b3ff, #0078d9);
  color: #fff;
}
.style-filtre {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}
input::placeholder {
  font-size: 12px;
  color: #d5d9d9;
}
.align-td-item {
  // justify-content: space-between;
  // display: flex;
  padding: 0px !important;
}
.customSelectMultipleFilter.libres {
  width: 100%;
  min-height: 29px !important;
  .multiselect__placeholder {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .multiselect__tags {
    padding: 3px 32px 0px 17px;
    min-height: 21px;
    border-radius: 50px !important;
    width: 100%;
  }
  .multiselect__select {
    width: 30px;
    height: 29px;
  }
  .multiselect__spinner {
    height: 27px;
    border-radius: 0px 50px 50px 0px;
    width: 22px;
  }
  .multiselect__option {
    padding: 4px;
    min-height: 27px;
    line-height: 16px;
    font-size: 11px !important;
  }
  .multiselect__single {
    display: none;
    font-size: 9px !important;
    height: 21px;
  }
  .multiselect__input {
    font-size: 9px !important;
    height: 21px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
  .showBlock {
    display: block;
    font-size: 9px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
  }
}

.libres .multiselect__option.multiselect__option--selected {
  background: #41b883;
  outline: none;
  color: #fff;
}

.libres .multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
}

.add-facture-style {
  display: inline-block;
  // margin-left: 14% !important;
  margin-bottom: 13px !important;
}
</style>
<style lang="scss">
.bars {
  &:focus,
  &:active {
    background-color: #6c757d !important;
    height: auto !important;
  }
}
.table-rapport-style .customTableFactureLibreVueDefault {
  .custom-checkbox {
    margin-right: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    width: 135px;
    min-width: 135px;
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .actions {
    position: sticky;
    right: -14px !important;
    background-color: #ededed;
  }
  .actions-avoir {
    position: sticky;
    right: -14px !important;
    background-color: #e0e0eb;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    vertical-align: middle;
    td {
      width: 135px;
      min-width: 135px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
.input-modal-champ-custom-avoir {
  .d-block {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px;
    text-align: center !important;
  }
  div {
    width: 99%;
  }
}
.loading-download {
  font-size: 9px;
  font-weight: 500;
  margin-top: 7px;
  .spinner-border {
    width: 0.7rem;
    height: 0.7rem;
  }
}
.displayed-none {
  display: none !important;
}
</style>
